// import React, { useState } from 'react'
// import { Download, Image as ImageIcon, Video, Music } from 'lucide-react'
// import PdfPreview from '../../pdf-review/PdfPreview'
// import { fetchChatOfChatterOrGroupByMessageId } from '../../../../zustand/cloud/cloud.action'
// import ClipLoader from 'react-spinners/ClipLoader';
// import { updateChatInStateAndDB } from '../../../../db/indexedDB';

// export function MediaUploaderDownloader({ db, setChats, chats, number, message, type }) {
//   const [media, setMedia] = useState(null);
//   const [fileName, setFileName] = useState(null);
//   const [isHovered, setIsHovered] = useState(false);
//   const [isMediaLoading, setIsMediaLoading] = useState(false);

//   const MediaContent = React.memo(({ media, mimetype }) => {
//     const updatedMediaUrl = media?.url?.replace(
//       "http://localhost:3000/",
//       "http://4.246.228.60:3000/"
//     );

//     if (mimetype?.includes("image")) {
//       return (
//         <img
//           src={updatedMediaUrl}
//           alt="Image Message"
//           className="w-[400px] object-contain rounded-lg cursor-pointer"
//         />
//       );
//     }

//     if (mimetype === "video/mp4") {
//       return (
//         <video
//           src={updatedMediaUrl}
//           className="w-[400px] rounded-lg cursor-pointer"
//           controls
//         />
//       );
//     }

//     if (mimetype?.includes("audio")) {
//       return (
//         <audio controls className="w-[300px] dark:text-white">
//           <source src={updatedMediaUrl} type={mimetype} />
//         </audio>
//       );
//     }

//     if (mimetype === "application/pdf") {
//       return <PdfPreview pdfUrl={updatedMediaUrl} fileName="document" />;
//     }

//     return null;
//   });

//   const handleDownload = async () => {
//     setIsMediaLoading(true);
//     try {
//       const data = await fetchChatOfChatterOrGroupByMessageId(
//         number,
//         message?.from,
//         message?.id
//       );
//       console.log("Downloaded data:", data);

//       const messageData = chats.find((chat) => chat.id === data?.id);
//       console.log('messageData', messageData);

//       if (messageData) {
//         // Filter out the old message data
//         const updatedChats = chats.filter((chat) => chat.id !== data?.id);

//         // Add the updated message data to the chats array
//         // setChats([...updatedChats, messageData]);

//         updateChatInStateAndDB(db, "chats", messageData, chats, setChats)
//       }

//       if (data?.media) {
//         setMedia(data.media); // Use the `media` field directly
//         setFileName(data?.media?.filename || "downloaded-file");
//       }
//     } catch (error) {
//       console.error("Error fetching chat data:", error);
//     } finally {
//       setIsMediaLoading(false);
//     }
//   };

//   return (
//     <div className='flex justify-between flex-col'>
//       <div className="flex flex-col items-center">
//         <div
//           className="relative border-2 border-dashed border-gray-300 rounded-lg overflow-hidden cursor-pointer group"
//           onMouseEnter={() => setIsHovered(true)}
//           onMouseLeave={() => setIsHovered(false)}
//           onClick={handleDownload}
//         >
//           {isMediaLoading ? (
//             <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
//               <ClipLoader />
//             </div>
//           ) : media ? (
//             <MediaContent media={media} mimetype={media?.mimetype} />
//           ) : (
//             <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
//               <Download className="w-8 h-8 text-white" />
//             </div>
//           )}
//         </div>

//         {!media && (
//           <p className="mt-2 text-sm text-gray-500">Download {type}</p>
//         )}
//       </div>
//     </div>
//   );
// }


import React, { useCallback, useEffect, useState } from "react";
import { Download, Image as ImageIcon, Video, Music } from "lucide-react";
import PdfPreview from "../../pdf-review/PdfPreview";
import { fetchChatOfChatterOrGroupByMessageId } from "../../../../zustand/cloud/cloud.action";
import ClipLoader from "react-spinners/ClipLoader";
import { addData, updateChatInStateAndDB } from "../../../../db/indexedDB";
import { update } from "lodash";

export function MediaUploaderDownloader({ db, setChats, chats, number, message, type, handleImageClick }) {
  const [media, setMedia] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [cache, setCache] = useState({}); // Cache for downloaded media

  const MediaContent = React.memo(({ media, mimetype, message }) => {
    const [renderCount, setRenderCount] = useState(0);
    const [mediaLoaded, setMediaLoaded] = useState(false);
  
    const updatedMediaUrl = useCallback(() => {
      return media?.url?.replace(
        "http://localhost:3000/",
        "https://waweb.eazybe.com/"
      );
    }, [media?.url]);
  
    const preloadMedia = useCallback(
      (url) => {
        if (!url || renderCount >= 5 || mediaLoaded) return;
  
        const mediaElement = new Image();
        mediaElement.src = url;
  
        mediaElement.onload = () => {
          setMediaLoaded(true);
          // console.log(`Media loaded successfully: ${url}`);
        };
  
        mediaElement.onerror = () => {
          console.error(`Failed to preload media: ${url}`);
          setRenderCount((prev) => prev + 1);
        };
      },
      [renderCount, mediaLoaded]
    );
  
    useEffect(() => {
      const url = updatedMediaUrl();
      if (url) {
        preloadMedia(url);
      }
    }, [updatedMediaUrl]);
  
    const handleError = (e) => {
      const url = updatedMediaUrl();
      if (e.target.src !== url) {
        e.target.src = url; // Fallback
      } else {
        console.error("Fallback media also failed to load.");
      }
    };
  
    // Render the text body or caption
    const renderTextContent = () => {
      const text = media?.caption || message?.body || ""; // Use caption first, then body
      if (!text) return null;
  
      // Highlight URLs in the text
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const parts = text.split(urlRegex);
  
      return (
        <p className="text-sm mb-2">
          {parts.map((part, index) =>
            urlRegex.test(part) ? (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {part}
              </a>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </p>
      );
    };
  
    // Render the media based on the mimetype
    const renderMediaContent = () => {
      if (mimetype?.includes("image")) {
        return (
          <>
          <img
            src={updatedMediaUrl()}
            alt="Click for render"
            className="w-[300px] object-contain rounded-lg cursor-pointer"
            onClick={() => handleImageClick(updatedMediaUrl())}
            onError={handleError}
          />
          </>
        );
      }
  
      if (mimetype === "video/mp4") {
        return (
          <video
            src={updatedMediaUrl()}
            className="w-[300px] rounded-lg cursor-pointer dark:text-white"
            controls
            onError={handleError}
          >
            Your browser does not support the video tag.
          </video>
        );
      }
  
      if (mimetype?.includes("audio")) {
        return (
          <audio controls className="w-[300px] dark:text-white" onError={handleError}>
            <source src={updatedMediaUrl()} type={mimetype} />
            Your browser does not support the audio element.
          </audio>
        );
      }
  
      if (mimetype === "application/pdf") {
        return <PdfPreview pdfUrl={updatedMediaUrl()} fileName={"document"} />;
      }
  
      return null;
    };
  
    // Main render
    return (
      <div className="media-content">
        {/* Render text content */}
        {renderTextContent()}
  
        {/* Render media content */}
        {renderMediaContent()}
      </div>
    );
  });

  const handleDownload = async () => {
    if (cache[message?.id]) {
      // Use cached media if available
      // console.log("Using cached media:", cache[message?.id]);
      setMedia(cache[message?.id]);
      setFileName(cache[message?.id]?.filename || "cached-file");
      return;
    }

    setIsMediaLoading(true);
    try {
      const data = await fetchChatOfChatterOrGroupByMessageId(
        number,
        message?.from,
        message?.id
      );
      // console.log("Downloaded data:", data);

      const messageData = chats.find((chat) => chat.id === data?.id);
      // console.log("messageData", messageData);

      if (messageData) {
        // Filter out the old message data
        const updatedChats = chats.filter((chat) => chat.id !== data?.id);

        let storeData = {
          id: data?.id,
          data: data,
        }
        // Update chat state and DB
        // updateChatInStateAndDB(db, "chats", messageData, chats, setChats);
        addData(db, "chats", storeData);
      }

      if (data?.media) {
        // Cache the media
        setCache((prevCache) => ({
          ...prevCache,
          [message?.id]: data.media,
        }));

        // Set media state
        setMedia(data.media);
        setFileName(data?.media?.filename || "downloaded-file");
      }
    } catch (error) {
      console.error("Error fetching chat data:", error);
    } finally {
      setIsMediaLoading(false);
    }
  };
  // console.log("MediaUploaderDownloader", message);
  
  return (
    <div className="flex justify-between flex-col">
      <div className="flex flex-col items-center cursor-pointer"
      onClick={handleDownload}
      >
        <div
          className={` ${media ? "" : "h-12 w-12"} relative border-2 border-dashed border-gray-300 rounded-lg overflow-hidden group`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isMediaLoading ? (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <ClipLoader />
            </div>
          ) : media ? (
            <MediaContent media={media} mimetype={media?.mimetype} message={message} />
          ) : (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <Download className="w-8 h-8 text-white" />
            </div>
          )}
        </div>

        {!media && (
          <>
            <p className="mt-2 text-sm text-gray-500">Download {type}</p>   
              <p className="text-sm mb-2">
                    <span>{message?.body}</span>
              </p>
          </>
        )}
      </div>
    </div>
  );
}
