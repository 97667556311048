import { differenceInCalendarDays, getDay } from "date-fns";

export const truncateMessage = (msg, group, maxLength = 55) => {
  // console.log('truncateMessage', msg);
  
  if(group == 'G'){
    maxLength = 33;
  }

  if (msg?.lastMessage?.hasMedia) {
    return (
      <div className="flex items-center gap-1">
        <span aria-hidden="true" data-icon="status-image" class="">
          <svg
            viewBox="0 0 16 20"
            height="20"
            width="16"
            preserveAspectRatio="xMidYMid meet"
            class=""
            version="1.1"
            x="0px"
            y="0px"
            enable-background="new 0 0 16 20"
          >
            <title>status-image</title>
            <path
              fill="#8696A0"
              d="M13.822,4.668H7.14l-1.068-1.09C5.922,3.425,5.624,3.3,5.409,3.3H3.531 c-0.214,0-0.51,0.128-0.656,0.285L1.276,5.296C1.13,5.453,1.01,5.756,1.01,5.971v1.06c0,0.001-0.001,0.002-0.001,0.003v6.983 c0,0.646,0.524,1.17,1.17,1.17h11.643c0.646,0,1.17-0.524,1.17-1.17v-8.18C14.992,5.191,14.468,4.668,13.822,4.668z M7.84,13.298 c-1.875,0-3.395-1.52-3.395-3.396c0-1.875,1.52-3.395,3.395-3.395s3.396,1.52,3.396,3.395C11.236,11.778,9.716,13.298,7.84,13.298z  M7.84,7.511c-1.321,0-2.392,1.071-2.392,2.392s1.071,2.392,2.392,2.392s2.392-1.071,2.392-2.392S9.161,7.511,7.84,7.511z"
            ></path>
          </svg>
        </span>
        <span dir="auto" aria-label="" className="overflow-x-hidden overflow-y-hidden whitespace-nowrap text-ellipsis inline-block relative flex-grow visible" style={{minHeight: '0px'}}>Photo</span>
      </div>
    );
  }
  else if(msg?.lastMessage?.body){
    // console.log('newMsg before', msg);
    let newMsg = msg?.lastMessage?.body;
    // console.log('newMsg after', newMsg);
    if (newMsg == null) {
      return "";
    }
    return newMsg.length > maxLength
      ? `${newMsg.substring(0, maxLength)}...`
      : newMsg;
  }
  else{
    // return "Open Chat"
    return (
      <div className="flex items-center gap-1">
        <span aria-hidden="true" data-icon="status-image" class="">
          <svg
            viewBox="0 0 16 20"
            height="20"
            width="16"
            preserveAspectRatio="xMidYMid meet"
            class=""
            version="1.1"
            x="0px"
            y="0px"
            enable-background="new 0 0 16 20"
          >
            <title>status-image</title>
            <path
              fill="#8696A0"
              d="M13.822,4.668H7.14l-1.068-1.09C5.922,3.425,5.624,3.3,5.409,3.3H3.531 c-0.214,0-0.51,0.128-0.656,0.285L1.276,5.296C1.13,5.453,1.01,5.756,1.01,5.971v1.06c0,0.001-0.001,0.002-0.001,0.003v6.983 c0,0.646,0.524,1.17,1.17,1.17h11.643c0.646,0,1.17-0.524,1.17-1.17v-8.18C14.992,5.191,14.468,4.668,13.822,4.668z M7.84,13.298 c-1.875,0-3.395-1.52-3.395-3.396c0-1.875,1.52-3.395,3.395-3.395s3.396,1.52,3.396,3.395C11.236,11.778,9.716,13.298,7.84,13.298z  M7.84,7.511c-1.321,0-2.392,1.071-2.392,2.392s1.071,2.392,2.392,2.392s2.392-1.071,2.392-2.392S9.161,7.511,7.84,7.511z"
            ></path>
          </svg>
        </span>
        <span dir="auto" aria-label="" className="overflow-x-hidden overflow-y-hidden whitespace-nowrap text-ellipsis inline-block relative flex-grow visible" style={{minHeight: '0px'}}>Photo</span>
      </div>
    );
  }
};

export const getLastMessageTime = (lastMessageTimeInMillis) => {
  lastMessageTimeInMillis = lastMessageTimeInMillis * 1000;
  const getDayOfTheWeek = (day) => {
    switch (day) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";

      default:
        return "Sunday";
    }
  };

  let differenceInDay = differenceInCalendarDays(
    new Date(),
    new Date(lastMessageTimeInMillis)
  );
  if (differenceInDay > 0) {
    if (differenceInDay === 1) {
      return "Yesterday";
    } else if (differenceInDay > 1 && differenceInDay < 8) {
      let dayOfWeek = getDay(new Date(lastMessageTimeInMillis));
      return getDayOfTheWeek(dayOfWeek);
    } else {
      return new Date(lastMessageTimeInMillis).toLocaleDateString();
    }
  } else {
    let date = new Date(lastMessageTimeInMillis);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return `${
      hours > 11
        ? hours - 12 === 0
          ? "12"
          : hours - 12 < 10
          ? `0${hours - 12}`
          : hours - 12
        : hours === 0
        ? "00"
        : hours < 10
        ? `0${hours}`
        : hours
    }:${minutes === 0 ? "00" : minutes < 10 ? `0${minutes}`:minutes} ${
      hours > 11 ? "pm" : "am"
    }`;
  }
};

export const languages = [
  { code: "en", name: "English" },
  { code: "es", name: "Spanish" },
  { code: "hi", name: "हिन्दी" },
  { code: "id", name: "Bahasa Indonesia" },
  { code: "pt", name: "Português" },
  { code: "pt_BR", name: "Português (Brasil)" },
  { code: "pt_PT", name: "Português (Portugal)" },
];

function convertReactionObject(input) {
  console.log('convertReactionObject', input);
  
  return {
      key: {
          remoteJid: input?.from,
          fromMe: input?.fromMe,
          id: input?.id
      },
      text: input?.reaction?.text,
      senderTimestampMs: input?.timestamp
  };
}

export function convertMessageObject(message) {
  console.log('convertMessageObject', message);
  
  const ackMap = {
    0: "SENT",
    1: "DELIVERED",
    2: "RECEIVED",
    3: "READ",
  };
  
  return {
    id: message?.id,
    timestamp: message?.timestamp?.low || message?.timestamp,
    from: message?.from,
    fromMe: message?.fromMe,
    to: message?.to,
    body: message?.body,
    hasMedia: message?.hasMedia,
    replyTo: message?.replyTo,
    media: {
      filename: message?.media?.filename,
      mimetype: message?.media?.mimetype,
      url: message?.media?.url
    },
    mediaUrl: message?.mediaUrl,
    ack: message?.ack,
    ackName: ackMap[message?.ack] || "UNKNOWN",
    vCards: message?.vCards,
    _data: {
      ...message?._data,
      ack: message?.ack,
      reactions: [...(message?.reactions || []), convertReactionObject(message)]
    },
  };
}

// export const addChatterToLocalStorage = (newChatter) => {
//   // Retrieve the existing chatter array from localStorage
//   const savedChatter = JSON.parse(localStorage.getItem("fetchedChatters")) || [];
  
//   // Add the new chatter to the array
//   savedChatter.push(newChatter);
  
//   // Save the updated array back to localStorage
//   localStorage.setItem("fetchedChatters", JSON.stringify(savedChatter));
// };

export const addChatterToLocalStorage = (newChatter) => {
  // Retrieve the existing chatter array from localStorage
  const savedChatter = JSON.parse(localStorage.getItem("fetchedChatters")) || [];
  
  // Check for duplicate entry using a unique identifier (e.g., id or phoneNumber)
  const isDuplicate = savedChatter.some((chatter) => chatter === newChatter);

  if (!isDuplicate) {
    // Add the new chatter to the array if it's not a duplicate
    savedChatter.push(newChatter);
    
    // Save the updated array back to localStorage
    localStorage.setItem("fetchedChatters", JSON.stringify(savedChatter));
  }
};


export const doesNumberExistInLocalStorage = (number) => {
  // Retrieve the array from localStorage
  const savedChatter = JSON.parse(localStorage.getItem("fetchedChatters")) || [];
  
  // Check if the number exists in the array
  const exists = savedChatter.some((item) => item === number);
  
  return exists;
};

export function updateReactions(reactions, emoji) {
  console.log('updateReactions', {reactions, emoji});
  
  // Ensure reactions is initialized as an array
  const updatedReactions = Array.isArray(reactions) ? [...reactions] : [];

  // Find index of the existing emoji reaction
  const existingReactionIndex = updatedReactions.findIndex(
    (reaction) => reaction.text === emoji
  );

  if (existingReactionIndex !== -1) {
    // If emoji exists, increment its count
    updatedReactions[existingReactionIndex].count += 1;
  } else {
    // If emoji doesn't exist, add it to the reactions
    updatedReactions.push({ text: emoji, count: 1 });
  }

  return updatedReactions;
}