import React, { useEffect, useState } from 'react'
import { X } from "lucide-react";
import { groupSubjectChange } from '../../../zustand/cloud/cloud.action';

const ChatsGroupUI = ({setIsClicked, isClicked, chatDetails, setSelectedChat}) => {
  // console.log('chatDetails', chatDetails);
  
  const [changeGroupName, setChangeGroupName] = useState(false);
  const [groupName, setGroupName] = useState(chatDetails?.data?.name);

  const handleGroupNameChange = async()=> {
    let body = {
      "subject": groupName
    }

    let number = localStorage.getItem("whatsappNumber");
    await groupSubjectChange(body, number, number, chatDetails?.data?.id)
  }

  const [isAdmin, setIsAdmin] = useState(false);
  function checkIsAdmin(number, usersArray) {
    const user = usersArray?.find(user => user?.id?.user === number);
    return user ? user.isAdmin : false;
  } 

  useEffect(()=> {
    if(chatDetails?.data?.id?.includes("@g.us")){
      let res = checkIsAdmin(localStorage.getItem("whatsappNumber"), chatDetails?.data?.groupMetadata?.participants);
      if(res){
        setIsAdmin(true);
      }
    } 
  }, [])

  return (
    <div className="h-[90vh] w-[30vw] bg-white text-foreground absolute top-1 right-[0.2rem] z-[100]">
            <div className="max-w-md mx-auto">
              <div className="flex items-center gap-4 p-4 border-b">
                <button onClick={()=> setIsClicked(!isClicked)} className="text-muted-foreground hover:text-foreground">
                  <X className="h-6 w-6" />
                </button>
                <h1 className="text-xl font-semibold">Contact info</h1>
              </div>

              {/* Profile Section */}
              <div className="flex flex-col items-center text-center py-8 px-4 border-b">
                <div className="w-32 h-32 mb-[3rem] rounded-full bg-muted flex items-center justify-center">
                {
                        chatDetails?.profilePic?.length > 0 ? 
                        (
                            <img src={chatDetails?.profilePic} className="object-cover rounded-full" />
                        ) 
                        : 
                        (
                            <span
                            aria-hidden="true"
                            data-icon="default-user"
                            className="w-25 h-25"
                            >
                            <svg
                                viewBox="0 0 212 212"
                                height="180"
                                width="180"
                                preserveAspectRatio="xMidYMid meet"
                                class="xh8yej3 x5yr21d"
                                version="1.1"
                                x="0px"
                                y="0px"
                                enable-background="new 0 0 212 212"
                            >
                                <title>default-user</title>
                                <path
                                fill="#DFE5E7"
                                class="background"
                                d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                                ></path>
                                <g>
                                <path
                                    fill="#FFFFFF"
                                    class="primary"
                                    d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                                ></path>
                                <path
                                    fill="#FFFFFF"
                                    class="primary"
                                    d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                                ></path>
                                </g>
                            </svg>
                            </span>
                        )
                    }
                </div>
                <div className='flex px-14 py-0 items-center gap-1'>
                  {
                    changeGroupName ? 
                    (
                      <input className='text-2xl text-[#575757]' type='text' value={groupName} onChange={(e)=> setGroupName(e.target.value)} />
                    ) 
                    : 
                    (
                      <h2 className="text-2xl text-[#575757] mb-2">{chatDetails?.data?.name || chatDetails?.data?.groupMetadata?.subject || chatDetails?.data?.id.split("@")[0]}</h2>
                    )
                  }
                  {
                    chatDetails?.data?.id.includes("@g.us") && isAdmin ? 
                    (
                      <>
                        <span onClick={()=> 
                          (
                            setChangeGroupName(true)
                          )}>
                          <span aria-hidden="true" data-icon="pencil" class="_ald7"><svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24"><title>pencil</title><path fill="currentColor" d="M3.95,16.7v3.4h3.4l9.8-9.9l-3.4-3.4L3.95,16.7z M19.75,7.6c0.4-0.4,0.4-0.9,0-1.3 l-2.1-2.1c-0.4-0.4-0.9-0.4-1.3,0l-1.6,1.6l3.4,3.4L19.75,7.6z"></path></svg></span>
                        </span>
                        {
                          changeGroupName && (
                            <span onClick={handleGroupNameChange}>
                              <span aria-hidden="true" data-icon="checkmark" class="_ald7"><svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24"><title>checkmark</title><path fill="currentColor" d="M9,17.2l-4-4l-1.4,1.3L9,19.9L20.4,8.5L19,7.1L9,17.2z"></path></svg></span>
                            </span>
                          )
                        }
                      </>
                    ) 
                    : 
                    (
                      null
                    )
                  }
                </div>
                {
                  chatDetails?.data?.id?.includes("@g.us") ? 
                  (
                    <>
                      <p className="text-muted-foreground text-[#9EABB4] mb-2">Group · {chatDetails?.data?.groupMetadata?.participants.length} members</p>
                    </>
                  ) 
                  : 
                  (
                    <p className="text-muted-foreground text-[#9EABB4] mb-4 mt-2">{chatDetails?.data?.id?.user}</p>
                  )
                }
              </div>

              {/* <div className="p-8 max-h-[20rem] overflow-y-auto">
                {chatDetails?.data?.id?._serialized.includes("@g.us") &&
                  chatDetails?.data?.groupMetadata?.participants?.map((user, index) => (
                    <div onClick={()=> setSelectedChat(user?.id)} className="cursor-pointer flex justify-between items-center mb-4" key={index}>
                      <span aria-hidden="true" data-icon="default-user" class="">
                        <svg
                          viewBox="0 0 212 212"
                          height="40"
                          width="40"
                          preserveAspectRatio="xMidYMid meet"
                          class="xh8yej3 x5yr21d"
                          version="1.1"
                          x="0px"
                          y="0px"
                          enable-background="new 0 0 212 212"
                        >
                          <title>default-user</title>
                          <path
                            fill="#DFE5E7"
                            class="background"
                            d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                          ></path>
                          <g>
                            <path
                              fill="#FFFFFF"
                              class="primary"
                              d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                            ></path>
                            <path
                              fill="#FFFFFF"
                              class="primary"
                              d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      <span className="text-sm">
                        {user?.id?.user}
                      </span>
                    </div>
                  ))
                }
              </div> */}
            </div>
    </div>
  )
}

export default ChatsGroupUI
