import { API, Functions, Util } from "..";


// /**
//  * @function setupNewMessageListener
//  * @async
//  * @description setup for new message listener
//  * @returns {Promise<void>}
//  * 
//  * @listens add - Listens to new added message.
//  * 
//  * @example 
//  * //For testing we can use imgBB
//  *  const file = await WPP.chat.downloadMedia(item.id._serialized).then(WPP.util.blobToBase64);
//  *  const uploadRes = await API.webhook.uploadToImgBB(file.split(",")[1]);
//  *  console.log("FILE", file, uploadRes);
//  */
// export const setupNewMessageListener = async (workspaceId) => {
//     try{
//     if(window.WPP) {
//             Functions.NewMessagesFunctions.updateMessagesFromWebhookLastSyncTime();

//             await API.webhook.getEazybeWebhookData(workspaceId || localStorage.getItem("currentOrgId"));

//             window.WPP.whatsapp.MsgStore.on("add", async (newMessage) => {

//   if (newMessage && newMessage.isNewMsg) {

//     const isWebhookEnabled = localStorage.getItem("isWebhookEnabled") ? JSON.parse(localStorage.getItem("isWebhookEnabled")) : false;

//     if(isWebhookEnabled) {
//       let item = newMessage;
//       const media = ['image', 'video', 'document'];
        
//       let msgObject = {
//         [item?.id?.remote?._serialized] : [{
//           MessageId: item.id.id ? item.id.id : item.id,
//           Message: media.includes(item.type) ? item.caption  ? item.caption : Util.capitalizeString(item.type) : item.body ? item.body : item.deprecatedMms3Url ? "File" : "",
//           ...(media.includes(item.type) ? {File: await WPP.chat.downloadMedia(item.id._serialized).then(WPP.util.blobToBase64)} : { File : null}),
//           Chatid: item?.id?.remote?._serialized,
//           FileName: item?.filename ? item.filename : null,
//           Ack: item.ack ? item.ack : 3,
//           Datetime: item.t * 1000,
//           Date: (new Date(item.t * 1000)).toJSON(),
//           Direction: item.id.fromMe ? "OUTGOING" : "INCOMING",
//           SentById: item.from._serialized
//             ? item.from._serialized
//             : item.from,
//           ChannelName: item?.senderObj?.__x_name,
//           ChannelId: item?.id?.remote?._serialized?.includes("g.us") ? item?.id?.remote?._serialized : "1:1",
//           CreatedByUser: Util.getLoggedInUserMobile(),
//           SentByNumber: item?.senderObj?.__x_id?.user,
//           sentByUserId: item?.senderObj?.__x_id?._serialized
//         }]
//       }

//       Functions.NewMessagesFunctions.handleUploadMessage(msgObject);
//     }
//   }
//           });
//         }
//     } catch(e) {
//       console.error("ERROR", e);
//     }
// }

/**
 * @function setupNewMessageListener
 * @async
 * @description setup for new message listener
 * @returns {Promise<void>}
 * 
 * @listens add - Listens to new added message.
 * 
 * @example 
 * //For testing we can use imgBB
 *  const file = await window.WPP.chat.downloadMedia(item.id._serialized).then(window.WPP.util.blobToBase64);
 *  const uploadRes = await API.webhook.uploadToImgBB(file.split(",")[1]);
 *  console.log("FILE", file, uploadRes);
 */
export const setupNewMessageListener = async (workspaceId) => {
  try {
    if (window.WPP) {
      Functions.NewMessagesFunctions.updateMessagesFromWebhookLastSyncTime();

      await API.webhook.getEazybeWebhookData(workspaceId || localStorage.getItem("currentOrgId"));

      window.WPP.whatsapp.MsgStore.on("add", async (newMessage) => {
        if (newMessage && newMessage.isNewMsg) {
          const isWebhookEnabled = localStorage.getItem("isWebhookEnabled")
            ? JSON.parse(localStorage.getItem("isWebhookEnabled"))
            : false;

          if (isWebhookEnabled) {
            let item = newMessage;
            const media = ['image', 'video', 'document'];

            let msgObject = {
              [item?.id?.remote?._serialized]: [
                {
                  MessageId: item.id.id ? item.id.id : item.id,
                  Message: media.includes(item.type)
                    ? item.caption
                      ? item.caption
                      : Util.capitalizeString(item.type)
                    : item.body
                    ? item.body
                    : item.deprecatedMms3Url
                    ? 'File'
                    : '',
                  ...(media.includes(item.type)
                    ? {
                        File: await window.WPP.chat
                          .downloadMedia(item.id._serialized)
                          .then(window.WPP.util.blobToBase64),
                      }
                    : { File: null }),
                  Chatid: item?.id?.remote?._serialized,
                  FileName: item?.filename ? item.filename : null,
                  Ack: item.ack ? item.ack : 3,
                  Datetime: item.t * 1000,
                  Date: new Date(item.t * 1000).toJSON(),
                  Direction: item.id.fromMe ? 'OUTGOING' : 'INCOMING',
                  SentById: item.from._serialized
                    ? item.from._serialized
                    : item.from,
                  ChannelName: item?.senderObj?.__x_name,
                  ChannelId: item?.id?.remote?._serialized?.includes('g.us')
                    ? item?.id?.remote?._serialized
                    : '1:1',
                  CreatedByUser: Util.getLoggedInUserMobile(),
                  SentByNumber: item?.senderObj?.__x_id?.user,
                  sentByUserId: item?.senderObj?.__x_id?._serialized,
                },
              ],
            };

            Functions.NewMessagesFunctions.handleUploadMessage(msgObject);
          }
        }
      });
    }
  } catch (e) {
    console.error('ERROR', e);
  }
};
