import useStorage from "../store/useSignupStore";

/**
 * @function verifyOTP
 * @async
 * @description verify otp for sign up.
 * @param {string} userEmail 
 * @param {string} otp 
 * @returns {Promise<object>}
 */
export const verifyOTP = async (userEmail,otp) => {
    try {
        const res = await  fetch(
            `https://eazybe.com/api/v1/whatzapp/verifyemailotpworkspace`,
            {
              body: JSON.stringify({
                userEmail: userEmail,
                otp: otp,
              }),
              method: "POST",
              headers: { "Content-Type": "application/json" },
            }
          )
            const response = await res.json();
            return response;
    } catch (error) {
        console.error("Failed to verify otp", error);
        return null;
    }
    
  };

  /**
 * @function checkIfUserExists
 * @async 
 * @description checks if user exsists in the database.
 * @param {string} userEmail 
 * @returns {Promise<userExistenceResponse>}
 */
  export const checkIfUserExists = async (userEmail) => {
     try {
         const res = await fetch(
           `https://eazybe.com/api/v1/whatzapp/checkuserexistance?user_email=${userEmail}`,
           {
             method: "GET",
             headers: {
               "Content-Type": "application/json",
             },
           }
         )
         const response = await res.json();
         return response;
     } catch (error) {
        console.error("Failed to check if user exists", error);
        return null;
     }

};

/**
 * @function signInUser
 * @async
 * @description this api is used to register user email.
 * @param {string} tempEmail 
 * @param {string} userMobile 
 * @param {string} userName 
 * @returns {Promise<object>}
 */
export const signInUser = async (tempEmail, userMobile, userName) => {
     try {
        const res = await  fetch(
           `https://eazybe.com/api/v1/whatzapp/registeremail?email=${tempEmail}&whatsapp_no${userMobile}&full_name${userName}`,
           {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
             },
           }
         );
   
         const response = await res.json();
         return response;
     } catch (error) {
        console.error("Failed to sign in user", error);
        return null;
     }
  };

/**
 * @function resendOTP
 * @async
 * @description used to send otp to user email.
 * @param {string} userEmail 
 * @returns {Promise<object>}
 */
 export const resendOTP = async(userEmail) => {
    try {
         const res = await fetch(`https://eazybe.com/api/v1/whatzapp/resendotp?email=${userEmail}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          });
          const response = await res.json();
          return response;
    } catch (error) {
        console.error("Failed to resend otp", error);
        return null;
    }
       
  };

  export const logoutUser = () => dispatch => dispatch({type: "LOGOUT"});
