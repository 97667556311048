import { X } from "lucide-react";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const GroupIndividualUI = ({setSelectedChat, loading, setGroupIndividualProfile, getChatter}) => {

    console.log('GroupIndividualUI', {setGroupIndividualProfile, getChatter});
    

  return (
    <>
    {loading ? (
        <div className="h-[90vh] w-[30vw] bg-white text-foreground absolute top-1 right-[0.2rem] z-[100] flex items-center justify-center">
            <ClipLoader />
        </div>
        ) : (
        <div className="h-[90vh] w-[30vw] bg-white text-foreground absolute top-1 right-[0.2rem] z-[100]">
            <div className="max-w-md mx-auto">
                <div className="flex items-center gap-4 p-4 border-b">
                <button
                    onClick={() => setGroupIndividualProfile(false)}
                    className="text-muted-foreground hover:text-foreground"
                >
                    <X className="h-6 w-6" />
                </button>
                <h1 className="text-xl font-semibold">Contact info</h1>
                </div>

                {/* Profile Section */}
                <div className="flex flex-col items-center text-center py-8 px-4 border-b mt-6">
                <div className="w-[12rem] h-32 mb-[3rem] rounded-full bg-muted flex items-center justify-center">
                    {
                        getChatter?.profilePic?.data?.profilePictureURL?.length > 0 ? 
                        (
                            <img src={getChatter?.profilePic?.data?.profilePictureURL} className="object-cover rounded-full" />
                        ) 
                        : 
                        (
                            <span
                            aria-hidden="true"
                            data-icon="default-user"
                            className="w-25 h-25"
                            >
                            <svg
                                viewBox="0 0 212 212"
                                height="180"
                                width="180"
                                preserveAspectRatio="xMidYMid meet"
                                class="xh8yej3 x5yr21d"
                                version="1.1"
                                x="0px"
                                y="0px"
                                enable-background="new 0 0 212 212"
                            >
                                <title>default-user</title>
                                <path
                                fill="#DFE5E7"
                                class="background"
                                d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                                ></path>
                                <g>
                                <path
                                    fill="#FFFFFF"
                                    class="primary"
                                    d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                                ></path>
                                <path
                                    fill="#FFFFFF"
                                    class="primary"
                                    d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                                ></path>
                                </g>
                            </svg>
                            </span>
                        )
                    }
                </div>
                <p className="text-2xl text-muted-foreground mb-2 text-[#575757]">{getChatter?.data?.name || getChatter?.data?.shortName || getChatter?.data?.pushname}</p>
                <h2 className="text-[#9EABB4] mb-4 mt-2">{getChatter?.data?.number}</h2>
                <span className="hover:cursor-pointer" onClick={() => (setSelectedChat(getChatter?.data?.id), setGroupIndividualProfile(false))}>
                    <span aria-hidden="true" data-icon="chat" class="x1n68mz9">
                        <svg
                        viewBox="0 0 24 24"
                        width="30"
                        preserveAspectRatio="xMidYMid meet"
                        class=""
                        version="1.1"
                        x="0px"
                        y="0px"
                        enable-background="new 0 0 24 24"
                        >
                        <title>chat</title>
                        <path
                            fill="currentColor"
                            enable-background="new    "
                            d="M19.005,3.175H4.674C3.642,3.175,3,3.789,3,4.821V21.02 l3.544-3.514h12.461c1.033,0,2.064-1.06,2.064-2.093V4.821C21.068,3.789,20.037,3.175,19.005,3.175z M14.016,13.044H7.041V11.1 h6.975V13.044z M17.016,9.044H7.041V7.1h9.975V9.044z"
                        ></path>
                        </svg>
                    </span>
                </span>
                </div>
            </div>
        </div>
    )}
    </>
  );
};

export default GroupIndividualUI;
