import React, { useEffect } from 'react'

const OnlyAdminMessage = () => {
  return (
    <div className="relative z-10 box-border flex-none order-3 min-h-[62px]">
        <div className='box-border flex items-center justify-center min-h-[62px] px-[19px] py-[14px] text-[14px] leading-[20px] text-[#667781] text-center dark:bg-[#1F2C33] bg-[#f0f2f5]' >
            <span>
                Only <span className='text-[#047EB4]'>admins</span> can send messages
            </span>
        </div>
    </div>
  )
}

export default OnlyAdminMessage
