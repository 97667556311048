import useSignUpStore from "../../services /store/useSignupStore";
import { getLoggedInUserMobile, getWorkspaceId } from "../../util";
import { APP_URL_PREFIX_V2, CLOUD_LOADING_TYPES, getSessionName, getUserEmail, getUserMobile, WAHA2 } from "../../utils/api";



/**
 * @function FETCH_CLOUD_DATA
 * @description wrapper to fetch data of cloud whatsapp from server
 * @param {string} path 
 * @param {string} method 
 * @param {object} body 
 * @param {string} sessionName 
 * @returns {Promise<Response>}
 */
// export const FETCH_CLOUD_DATA = (path, method="GET",body,sessionName) => {
//     console.log('FETCH_CLOUD_DATA', {
//         path, body, sessionName
//     });
    
//     try {
//         // const authToken = getUserAccessToken();
//         const authToken = null;
//         return  fetch(`${WAHA2}${path}`,{
//             method: "POST",
//             headers: {
//                 "Content-type": "application/json; charset=UTF-8",
//             }, 
//             body: JSON.stringify(body)
//         });
        
//     } catch (error) {
//         console.error("Failed to fetch cloud data", path , error);
//         return null;
//     }
// }

export const FETCH_CLOUD_DATA = (path, method="GET",body) => {
    try {
        const options = {
            method: method,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        };
    
        if (body) {
            options.body = JSON.stringify(body);
        }
    
        return fetch(`${WAHA2}${path}`, options);
        
    } catch (error) {
        console.error("Failed to fetch cloud data", path , error);
        return null;
    }
}

export const fetchWhatsappChatter = async()=>{
    try {
        const sessionName = useSignUpStore.getState().userData?.mobile;
        if(!sessionName) {
            throw new Error("Invalid session name");
        }
        const res = await FETCH_CLOUD_DATA(`/api/${sessionName}/chats`, "GET", null, sessionName)

        if (!res.ok) {
            throw new Error("Failed to fetch contact from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to fetch contact from cloud session", error);
        return null;
    }
}

export const fetchChatOfChatterOrGroup = async (sessionName, chatId, timestamp) => {
    try {
        // Construct the URL conditionally based on whether `timestamp` is defined
        const baseUrl = `/api/${sessionName}/chats/${chatId}/messages?limit=300&downloadMedia=false`;
        const url = timestamp !== undefined 
            ? `${baseUrl}&filter.timestamp.gte=${timestamp}` 
            : baseUrl;

        // Fetch the data
        const res = await FETCH_CLOUD_DATA(url, "GET", null, sessionName);

        // Handle non-OK responses
        if (!res.ok) {
            throw new Error("Failed to fetch chat from cloud session from server");
        }

        // Parse and return the response
        const response = await res.json();
        return response;

    } catch (error) {
        console.error("Failed to fetch chat from cloud session", error);
        return null;
    }
};


export const fetchChatOfChatterOrGroupByMessageId = async(sessionName, chatId, messageId)=> {
    try {
        const res = await FETCH_CLOUD_DATA(`/api/${sessionName}/chats/${chatId}/messages/${messageId}?downloadMedia=true`, "GET", null, sessionName)

        if (!res.ok) {
            throw new Error("Failed to fetch chat from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to fetch chat from cloud session", error);
        return null;
    }
}

export const sendMessage = async(body, sessionName)=> {
    try {
        const res = await FETCH_CLOUD_DATA(`/api/sendText`, "POST", body, sessionName)

        if (!res.ok) {
            throw new Error("Failed to send message from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to send message from cloud session", error);
        return null;
    }
}

export const reactEmoji = async(body, sessionName)=> {
    try {
        const res = await FETCH_CLOUD_DATA(`/api/reaction`, "PUT", body, sessionName)

        if (!res.ok) {
            throw new Error("Failed to send message from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to send message from cloud session", error);
        return null;
    }
}

export const sessionActiveorNot = async(sessionName)=> {
    try {
        const res = await FETCH_CLOUD_DATA(`/api/sessions/${sessionName}`, "GET", null, sessionName)

        if (!res.ok) {
            throw new Error("Failed to get sesssion info from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to get session info from cloud session", error);
        return null;
    }
}

export const sendImagesorVideo = async(payload, sessionName)=> {
    console.log('sendImagesorVideo', {payload, sessionName});
    
    try {
        const res = await FETCH_CLOUD_DATA(`/api/sendImage`, "POST", payload, sessionName)

        if (!res.ok) {
            throw new Error("Failed to get sesssion info from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to get session info from cloud session", error);
        return null;
    }
}

export const sendAudioVoiceRecords = async(payload, sessionName)=> {
    console.log('sendImagesorVideo', {payload, sessionName});
    
    try {
        const res = await FETCH_CLOUD_DATA(`/api/sendVoice`, "POST", payload, sessionName)

        if (!res.ok) {
            throw new Error("Failed to get sesssion info from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to get session info from cloud session", error);
        return null;
    }
}

export const sendfiles = async(payload, sessionName)=> {
    console.log('sendfiles', {payload, sessionName});
    
    try {
        const res = await FETCH_CLOUD_DATA(`/api/sendFile`, "POST", payload, sessionName)

        if (!res.ok) {
            throw new Error("Failed to get sesssion info from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to get session info from cloud session", error);
        return null;
    }
}

export const restartSession = async (sessionName) => {
    try {
        const res = await FETCH_CLOUD_DATA(`/api/sessions/${sessionName}/restart`, "POST",{
            session: sessionName
        }, sessionName )

        if (!res.ok) {
            throw new Error("Failed to restart cloud session from server");
        }

        const response = await res.json();
        return response;

    } catch (error) {
        console.error("Failed to restart cloud session", error);
        return null;
    }
}

export const startCloudConnection = async (userInitialData) => {
    try {
        
        const  body = {
          "phone": getUserMobile(userInitialData),
          "workspace_id": getWorkspaceId(),
          "session_name": getUserMobile(userInitialData),
          "cloud_enabled": 1,
        //   "email": getUserEmail(userInitialData),
        //   "timezone_offset": new Date().getTimezoneOffset(),
          "mode": "enterprise"
        };

        const res = await fetch(`${APP_URL_PREFIX_V2}cloud/toggle`,{
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(body)
        })

        if (!res.ok) {
            throw new Error("Error from server");
        }

        const response = await res.json();

        if(!response.status) {
            throw new Error("Failed to create entry on backend server");
        }

        // adding 3 seconds delay.
        await new Promise((res) => setTimeout(res, 3000));

        console.log("Starting cloud connection", body);
        
        const sessionResponse = await startSession(body);

        console.log("Starting cloud connection sessionResponse", sessionResponse);
        return await handleCloudResponse(sessionResponse, userInitialData ?? useSignUpStore.getState().userData);
    } catch (error) {
        console.error("Failed to start cloud connection", error);
        return null;
    }
}

export const stopCloudConnectionOnServer = async (userInitialData) => {
    try {
        const workspaceId = await getWorkspaceId();
        const email = await getUserEmail(userInitialData);
        const mobile = await getUserMobile(userInitialData);
        const  body = {
          "phone": mobile,
          "workspace_id": workspaceId,
          "session_name": `${mobile}`,
          "cloud_enabled": 0,
          "email": email
        };

        const res = await fetch(`${APP_URL_PREFIX_V2}/cloud/toggle`,{
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(body)
        })

        if (!res.ok) {
            throw new Error("Error from server");
        }

        const response = await res.json();

        if(!response.status) {
            throw new Error("Failed to update entry on backend server");
        }

        return response;

    } catch (error) {
        console.error("Failed to stop cloud session on server", error);
        return null;
    }
}

export const deleteSession = async (userInitialData) => {
    try {
        const sessionName = userInitialData?.data?.mobile;
        const res = await FETCH_CLOUD_DATA(`/api/sessions/${sessionName}`,
            "DELETE", 
            {
                session: sessionName
            },
            sessionName
        );

        if (!res.ok) {
            throw new Error("Failed to restart cloud session from server");
        }

        const response = await res.json();
        return response;

    } catch (error) {
        console.error("Failed to delete cloud session", error);
        return null;
    }
}

export const getCloudSessionQR = async (userInitialData) => {
    try {
        const sessionName = getSessionName(userInitialData);
        console.log('getCloudSessionQR', sessionName);
        
        const session = sessionName ?? useSignUpStore.getState().userData?.mobile
        const res = await FETCH_CLOUD_DATA(`/api/${session}/auth/qr?format=image`, "GET", null,session)
        console.log('getCloudSessionQR res', res);
        
        if(!res.ok) {
            throw new Error("Failed to get QR");
        }

        const blob = await res.blob();

        // Create a Base64 string from the Blob
        const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract the Base64 string
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });

        return base64;

        const response = await res.json();
        return await handleCloudResponse(response, userInitialData ?? useSignUpStore.getState().userData);

    } catch (error) {
        console.error("Failed to get session qr", error);
        return null;
    }
}

export const startSession = async (body) => {
    console.log('startSession');
    
    try {
      if(!body){
          throw new Error("Invalid employee data");
      }
  
      const res = await FETCH_CLOUD_DATA("/api/sessions","POST",{
          "name": body.session_name,
          "start": true,
          "config": {
              "metadata": {
                  "workspace_id": `${body.workspace_id}`,
                  "phone": `${body.phone}`
              },
              "proxy": null,
              "debug": false,
              "noweb": {
                  "store": {
                      "enabled": true,
                      "fullSync": false
                  }
              },
              "webhooks": [
                  {
                      "url": `${APP_URL_PREFIX_V2}cloud/webhook`,
                      "events": [
                          "message.any",
                          "session.status",
                          "message.reaction"
                      ],
                      "hmac": null,
                      "retries": null,
                      "customHeaders": null
                  }
              ]
          }
      },
      body.session_name);
      
      console.log('startSession', res);
      
        // if (!res.ok) {
        //     throw new Error("Error from WAHA server");
        // } 
    
        // const response = await res.json();
  
        // if(!response.status) {
        //   if(!response.data) {
        //       throw new Error("Failed to start session");
        //   } else {
              if(res?.status === 422){ 
                console.log('restartSession');
                
                  // session already created, restart session.
                  return restartSession(body.session_name)
              }
        //   }
        // }
        
        return res;
    } catch (error) {
      console.error("Failed to start cloud session", error);
      return null;
    }
}

export const getSessionInfo = async (sessionName) => {
    try {
        const session = sessionName ?? useSignUpStore.getState().userData?.mobile
        const res = await FETCH_CLOUD_DATA(`/api/sessions/${session}`,"GET",null,session);

        if (!res.ok) {
            throw new Error("Failed to stop cloud session from server");
        }

        const response = await res.json();
        return await handleCloudResponse(response, useSignUpStore.getState().userData);
    } catch (error) {
        console.error("Failed to get cloud session info", error);
        return null;
    }
}

const handleCloudResponse = async (response, userInitialData, employee) => {
    try {
        console.log('handleCloudResponse', {response, userInitialData, employee});
        
        if(!response) {
            console.log('handleCloudResponse inside if 1');
            if(response && response?.statusCode === 404){
                console.log('handleCloudResponse inside if 2');
                // start connection
                // await startSession(employee ??  {
                await startSession({
                  "phone": getUserMobile(userInitialData),
                  "workspace_id":  getWorkspaceId(userInitialData),
                  "session_name": getSessionName(userInitialData),
                  "cloud_enabled": 1,
                  "email": getUserEmail(userInitialData),
                  "timezone_offset": new Date().getTimezoneOffset()
                });
                
                startPollingForSessionUpdate(userInitialData?.mobile ,async (res) => {
                    console.log('handleCloudResponse inside startPollingForSessionUpdate');
                    switch (res.status) {
                      case CLOUD_LOADING_TYPES.SCAN_QR_CODE:
                          return await getCloudSessionQR(userInitialData?.mobile)
    
                        case CLOUD_LOADING_TYPES.WORKING:
                            return res;
                    
                      default:
                            return null
                    }
                  },
                  CLOUD_LOADING_TYPES.STARTING
                )
            }
    
            throw new Error("Failed to handle cloud response");
        }
        
        return response;
    } catch (error) {
        console.error("Failed to handle cloud response", error);
        return null;
    }
    }

    export const startPollingForSessionUpdate = (sessionName,callBack,initialStatus,maxCount=20) => {
        let count = 0;
        console.log('startPollingForSessionUpdate', {sessionName,callBack,initialStatus});
        
       const pollingFunction = async () => {
            count += 1;
            const response = await getSessionInfo(sessionName);
            
            if(response && response.status !== initialStatus) {
                    callBack(response);
    
                    if(interval) {
                        clearInterval(interval);
                    }
            }
    
            if(count === maxCount) {
                if(interval) {
                        callBack(response);
                        clearInterval(interval);
                }
            }
        }
            var interval = setInterval(pollingFunction, 7000);
    }

    /**
 * @function  setCloudSessionInLocal
 * @description function to set cloud session status in local storage.
 * This is important as this will direct if scheduled message is to be sent from frontend or cloud.
 * depending on the value it has.
 * @param {string} session 
 * @returns {Promise<void>}
 */
export const setCloudSessionInLocal = async (session) => {
    try {
      localStorage.setItem("CLOUD_SESSION_STATUS", session);
    } catch (e) {
      console.error("Failed to set cloud session status in local", e);
    }
}

/**
* @function  getCloudSessionFromLocal
* @description function to get cloud session status from local storage.
* @returns {Promise<string>}
*/
export const getCloudSessionFromLocal = async () => { 
  try {
    let result = localStorage.getItem("CLOUD_SESSION_STATUS");
    return result;
  } catch (e) {
    console.error("Failed to get cloud session status from local", e);
    return null;
  }
};


//group 
export const groupMemberDetails = async(sessionName, session, id)=>{
    try {
        const res = await FETCH_CLOUD_DATA(`/api/${session}/groups/${id}/participants`, "GET", null, sessionName)

        if (!res.ok) {
            throw new Error("Failed to fetch contact from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to fetch contact from cloud session", error);
        return null;
    }
}

export const groupSubjectChange = async(body, sessionName, session, id)=>{
    try {
        console.log('groupSubjectChange', {body, sessionName, session, id});
        
        const res = await FETCH_CLOUD_DATA(`/api/${session}/groups/${id}/subject`, "PUT", body, sessionName)

        if (!res.ok) {
            throw new Error("Failed to change subject from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to change subject from cloud session", error);
        return null;
    }
}

export const getChatterInfo = async(sessionName, session, id)=>{
    try {
        console.log('getChatterInfo', {sessionName, session, id});
        
        const res = await FETCH_CLOUD_DATA(`/api/contacts?contactId=${id}&session=${session}`, "GET", null, sessionName)

        if (!res.ok) {
            throw new Error("Failed to getChatterInfo from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to getChatterInfo from cloud session", error);
        return null;
    }
}



export const getProfilePicture = async(sessionName, session, id)=>{
    try {
        console.log('getChatterInfo', {sessionName, session, id});
        
        const res = await FETCH_CLOUD_DATA(`/api/contacts/profile-picture?contactId=${id}&&session=${session}`, "GET", null, sessionName)

        if (!res.ok) {
            throw new Error("Failed to getChatterInfo from cloud session from server");
        }

        const response = await res.json();

        return response;

    } catch (error) {
        console.error("Failed to getChatterInfo from cloud session", error);
        return null;
    }
}