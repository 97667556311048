import React, { useEffect, useState, useRef } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  MoreVertical,
  Search,
  Moon,
  Sun,
  Globe,
  ChevronDown,
} from "lucide-react";
import Chatter from "../chatterBox/Chatter";
import { languages } from "../../../utils/helper";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { openDB } from "../../../db/indexedDB";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const ChatterSidebar = ({
  number,
  darkMode,
  chatter,
  toggleDarkMode,
  selectedChat,
  setSelectedChat,
  currentLanguage,
  setCurrentLanguage,
  setChatter,
  pusherData,
  visibleChats,
  setVisibleChats,
  setGroupIndividualProfile,
  groupIndividualProfile,
  setChats,
  chats,
  handleAddChats
}) => {
  // console.log("ChatterSidebar", number, chatter);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [logoutDropDown, setLogoutDropDown] = useState(false);
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const [loading, setLoading] = useState(false);
  const [chatsCount, setChatsCount] = useState(20);
  const lastFetchCount = useRef(0);
  const chatContainerRef = useRef(null); // Ref to the chat container
  const [searchTerm, setSearchTerm] = useState("");
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("preferredLanguage");
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const [db, setDb] = useState(null);
  useEffect(() => {
    const initializeDB = async () => {
      const dbName = "whatsappDB";
      const dbInstance = await openDB(dbName, 2);
      setDb(dbInstance);
    };

    initializeDB();
  }, [selectedChat]);

  useEffect(() => {
    console.log("Rendered visibleChats in JSX", visibleChats);
  }, [visibleChats]);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);

  const fiveChatsData = async () => {
    for (let i = start; i < end; i++) {
      // console.log("fiveChatsData", chatter[i]);

      if (chatter[i]) {
        try {
          // Call the API synchronously for each chat
          // console.log('API CALLS FOR FETCHING CHATTERS CHATS');
          
          await handleAddChats(number, chatter[i]?.id, chatter[i]);
        } catch (error) {
          console.error("Error calling API for chat:", chatter[i]?.id, error);
        }
      }
    }
    // Move the state updates outside the loop to update them only once
    setStart((prevStart) => prevStart + 20);
    setEnd((prevEnd) => prevEnd + 20);
  };

  const loadMoreChats = () => {
    if (loading || !hasMoreChats) return;

    setLoading(true);
    fiveChatsData();
    setTimeout(() => {
      const nextChats = chatter.slice(chatsCount, chatsCount + 20);
      setVisibleChats((prevChats) => [...prevChats, ...nextChats]);
      setChatsCount((prevCount) => prevCount + 20);

      if (nextChats.length < 20) {
        setHasMoreChats(false);
      }

      setLoading(false);
    }, 100);
  };

  const handleScroll = debounce(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        loadMoreChats();
        console.log('loadMoreChats');
        
      }
    }
  }, 200);

  const handleLanguageChange = (langCode) => {
    setCurrentLanguage(langCode);
    localStorage.setItem("preferredLanguage", langCode);
    i18n.changeLanguage(langCode);
    setIsDropdownOpen(false);
  };

  const { t } = useTranslation();

  function clearIndexedDB() {
    const dbName = "whatsappDB"; // Name of your database

    // Delete the database
    const deleteRequest = indexedDB.deleteDatabase(dbName);

    // Success handling
    deleteRequest.onsuccess = function () {
      // console.log(`${dbName} database deleted successfully`);
    };

    // Error handling
    deleteRequest.onerror = function (event) {
      console.error("Error deleting database:", event);
    };

    // Optional: Handle blocked state if there's an active connection to the DB
    deleteRequest.onblocked = function () {
      console.warn(
        "Database deletion blocked, perhaps because another connection is still open"
      );
    };
  }

  const navigate = useNavigate();
  const handleLogout = () => {
    try {
      localStorage.removeItem("currentOrgCode");
      localStorage.removeItem("currentOrgId");
      localStorage.removeItem("userMobile");
      localStorage.removeItem("currentOrgName");
      localStorage.removeItem("workspaceId");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("eazybe-storage");
      localStorage.removeItem("userRoleId");
      localStorage.removeItem("whatsappNumber");
      localStorage.removeItem("userInitialData");
      localStorage.removeItem("darkMode");

      setSelectedChat("");
      setCurrentLanguage("");
      setVisibleChats("");
      clearIndexedDB();
      setLogoutDropDown(false);

      console.log("Logout");
      navigate("/signup");
    } catch (err) {
      console.log("Error while logging out", err);
    }
  };

  const [filterChats, setFilterChats] = useState([]);

  useEffect(() => {
    const filteredChatters = chatter.filter(
      (chat) =>
        chat?.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        chat?.id?.split("@")[0]?.toLowerCase()?.includes(searchTerm)
    );
    setFilterChats(filteredChatters);
  }, [searchTerm]);

  // console.log('filterChats', filterChats);

  const handleSearch = (e) => {
    const trimmedValue = e.target.value.trim();

    if (trimmedValue.length > 0) {
      setSearchTerm(trimmedValue); // Only set if input is non-empty
      setOpenSearch(true);
      console.log("handleSearch", trimmedValue, { filterChats });
    } else {
      setOpenSearch(false); // Close search if input is empty after trimming
      setSearchTerm(""); // Reset search term
    }
  };

  const [openSearch, setOpenSearch] = useState(false);

  // console.log("visible chat", visibleChats);

  return (
    <div className="w-1/3 bg-white dark:bg-[#111B21] border-r border-gray-300 dark:border-gray-700 flex flex-col">
      <div className="p-4 bg-white dark:bg-[#111B21] flex justify-between items-center">
        <span className="text-2xl dark:text-white font-semibold">Chats</span>

        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center space-x-1 text-gray-600 dark:text-gray-300 focus:outline-none"
          >
            <MoreVertical className="w-6 h-6" />
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 py-2 w-48 bg-white dark:bg-gray-700 rounded-md shadow-xl z-20">
              <button
                onClick={toggleDarkMode}
                className="block w-full flex items-center gap-5 text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                Dark Mode
                {darkMode ? (
                  <Sun className="w-6 h-6 text-yellow-300" />
                ) : (
                  <Moon className="w-6 h-6 text-gray-600" />
                )}
              </button>
              <div className="border-t border-gray-300 dark:border-gray-600 my-1"></div>

              {/* Language Dropdown */}
              <div className="relative">
                <button
                  onClick={() =>
                    setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
                  }
                  className="block w-full flex items-center gap-5 text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <Globe className="w-6 h-6" />
                  {currentLanguage || "Select Language"}
                  <ChevronDown className="w-4 h-4" />
                </button>
                {isLanguageDropdownOpen && (
                  <div className="absolute left-0 mt-1 py-1 w-48 bg-white dark:bg-gray-700 rounded-md shadow-xl z-20">
                    {languages.map((lang) => (
                      <button
                        key={lang.code}
                        onClick={() => handleLanguageChange(lang.code)}
                        className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-left"
                      >
                        {lang.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div className="border-t border-gray-300 dark:border-gray-600 my-1"></div>
              <button
                onClick={handleLogout}
                className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-left"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="p-2">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => handleSearch(e)}
            placeholder={t("Search_or_start_new_chat.message")}
            className="w-full p-2 pl-10 bg-[#EFF2F5] dark:bg-[#1F2C33] rounded-lg text-gray-800 dark:text-gray-200 focus:outline-none"
          />
          <Search className="absolute left-3 top-2.5 w-4 h-4 text-gray-400 dark:text-gray-500" />
        </div>
      </div>
      <div
        ref={chatContainerRef} // Adding ref to chat container
        className="overflow-y-auto h-[calc(100%-128px)]"
        onScroll={handleScroll} // Attach scroll event handler
      >
        {Array.isArray(visibleChats) && visibleChats.length > 0 ? (
          visibleChats.map((chat) => (
            <>
              <Chatter
                key={chat?.id}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                chat={chat}
                chatter={chatter}
                setChatter={setChatter}
                setGroupIndividualProfile={setGroupIndividualProfile}
                setChats={setChats}
                chats={chats}
                db={db}
              />
              <hr className="border-t-1 border-[#E9EDEF] dark:border-[#222D34] ml-[4.8rem]" />
            </>
          ))
        ) : (
          <div className="h-[90vh] flex justify-center items-center">
            <ClipLoader />
            {/* <EazybeLoader /> */}
          </div>
        )}
        {loading && (
          <div className="flex justify-center p-4">
            <ClipLoader />
            {/* <EazybeLoader /> */}
          </div>
        )}
      </div>
      {openSearch &&
      (
        <div className="overflow-y-auto h-[calc(100%-128px)] absolute bg-white dark:bg-[#111B21] w-1/3 top-[7.5rem]">
          {Array.isArray(filterChats) && filterChats.length > 0 ? (
            filterChats.map((chat) => (
              <>
                <Chatter
                  key={chat?.id}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  chat={chat}
                  chatter={chatter}
                  setChatter={setChatter}
                  setGroupIndividualProfile={setGroupIndividualProfile}
                  setChats={setChats}
                  chats={chats}
                  db={db}
                />
                <hr className="border-t-1 border-[#E9EDEF] dark:border-[#222D34] ml-[4.8rem]" />
              </>
            ))
          ) : (
            <div className="h-[90vh] flex justify-center items-center">
              <ClipLoader />
              {/* <EazybeLoader /> */}
            </div>
          )}
      </div>
      )
      }
    </div>
  );
};

export default ChatterSidebar;
