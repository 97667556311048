import React, { useState } from 'react';

export default function WhatsAppImagePopup({ isOpen, setIsOpen, imageUrl, alt = 'Image' }) {
    // console.log('WhatsAppImagePopup', imageUrl, alt);
    
  const closePopup = () => setIsOpen(false);

  return (
    <>
      {/* Popup Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4">
            <button
                onClick={closePopup}
                className="absolute top-[3rem] right-[5rem] text-white hover:text-gray-700"
                >
                ✕
            </button>  
          <div className="relative max-w-3xl w-full bg-white rounded-lg shadow-lg">
            {/* Close Button */}
            {/* Full Image */}
            <div className="p-1">
              <img
                src={imageUrl}
                alt={alt}
                className="w-full h-[80vh] object-contain rounded-lg"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
