import {create} from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware'
import { checkIfUserExists } from '../api/signup';

    const useSignUpStore = create(persist((set,get) => ({
      isUserLoggedIn: false,
      userData: null,
      checkUserExistence : async (userEmail) => {
        const signUpResponse = await checkIfUserExists(userEmail)
        if(signUpResponse.type && signUpResponse.data) {
          set({userData: signUpResponse.data , isUserLoggedIn: true});
         }
      }
    }),{
      name: 'eazybe-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage)
    }));

export default useSignUpStore;



// ZUSTAND EXAMPLE
// import { create } from 'zustand'

// const useFishStore = create(
//   persist(
//     (set, get) => ({
//       fishes: 0,
//       addAFish: () => set({ fishes: get().fishes + 1 }),
//     }),
//     {
//       name: 'food-storage', // name of the item in the storage (must be unique)
//       storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
//     },
//   ),
// )