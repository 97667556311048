  // export const openDB = (dbName, version) => {
  //   return new Promise((resolve, reject) => {
  //     const request = indexedDB.open(dbName, version);
  
  //     request.onupgradeneeded = (event) => {
  //       const db = event.target.result;
  
  //       // Create object stores if they don't exist
  //       if (!db.objectStoreNames.contains('chatter')) {
  //         db.createObjectStore('chatter', { keyPath: 'id' });
  //       }
  
  //       if (!db.objectStoreNames.contains('chats')) {
  //         db.createObjectStore('chats', { keyPath: 'id' });
  //       }
  //     };
  
  //     request.onsuccess = (event) => {
  //       resolve(event.target.result);
  //     };
  
  //     request.onerror = (event) => {
  //       reject(event.target.error);
  //     };
  //   });
  // };

  export const openDB = (dbName, version) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Create or upgrade the "chatter" object store
      if (!db.objectStoreNames.contains("chatter")) {
        db.createObjectStore("chatter", { keyPath: "id" });
      }

      // Create or upgrade the "chats" object store
      if (!db.objectStoreNames.contains("chats")) {
        const store = db.createObjectStore("chats", { keyPath: "id" });

        // Create indexes for "chats" store
        store.createIndex("to", "data.to", { unique: false });
        store.createIndex("from", "data.from", { unique: false });
        store.createIndex("timestamp", "data.timestamp", { unique: false });
      } else {
        // If the "chats" store exists, ensure indexes are created
        const transaction = event.target.transaction;
        const store = transaction.objectStore("chats");

        // Safely create indexes only if they don't already exist
        if (!store.indexNames.contains("to")) {
          try {
            store.createIndex("to", "data.to", { unique: false });
          } catch (error) {
            console.warn("Index 'to' already exists or could not be created.", error);
          }
        }
        if (!store.indexNames.contains("from")) {
          try {
            store.createIndex("from", "data.from", { unique: false });
          } catch (error) {
            console.warn("Index 'from' already exists or could not be created.", error);
          }
        }
        if (!store.indexNames.contains("timestamp")) {
          try {
            store.createIndex("timestamp", "data.timestamp", { unique: false });
          } catch (error) {
            console.warn("Index 'timestamp' already exists or could not be created.", error);
          }
        }
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};
  
  export const addData = (db, storeName, data) => {
    // console.log('storing data in db', data);
    
    return new Promise((resolve, reject) => {
      const transaction = db?.transaction(storeName, 'readwrite');
      const store = transaction?.objectStore(storeName);
      const request = store?.put(data);
      
      request.onsuccess = () => {
        resolve(true);
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };
  
  export const getData = (db, storeName, key) => {
    return new Promise((resolve, reject) => {
      const transaction = db?.transaction(storeName, 'readonly');
      const store = transaction?.objectStore(storeName);
      const request = store?.get(key);
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  export const getChatsByPrefix = async (dbInstance, storeName, selectedChatPrefix) => {
    const tx = dbInstance?.transaction(storeName, "readonly");
    const store = tx?.objectStore(storeName);
  
    const allChats = [];
    
    return new Promise((resolve, reject) => {
      const request = store?.openCursor();
      
      request.onsuccess = (event) => {
        const cursor = event?.target?.result;
        if (cursor) {
          const key = cursor?.primaryKey;
          // if (key.startsWith(selectedChatPrefix)) {
          if (key.includes(selectedChatPrefix)) {
            allChats.push(cursor?.value); // Add matched chats
          }
          cursor.continue();
        } else {
          resolve(allChats);
        }
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };
  
  //gives sorted chats 
  // export const getChatsByToProperty = async (dbInstance, storeName, toValue) => {
  //   const tx = dbInstance?.transaction(storeName, "readonly");
  //   const store = tx?.objectStore(storeName);
  
  //   const matchingChats = [];
  
  //   return new Promise((resolve, reject) => {
  //     const request = store?.openCursor();
  
  //     request.onsuccess = (event) => {
  //       const cursor = event.target.result;
  //       if (cursor) {
  //         const chatData = cursor?.value;
  
  //         // Check if the 'to' or 'from' property matches the desired value
  //         if (chatData?.data?.to === toValue || chatData?.data?.from === toValue) {
  //           matchingChats.push(chatData); // Add matched chats to the result array
  //         }
  
  //         cursor?.continue(); // Continue to the next entry
  //       } else {
  //         // Sort the matching chats by timestamp before resolving
  //         const sortedChats = matchingChats.sort((a, b) => a.data.timestamp - b.data.timestamp);
  //         resolve(sortedChats); // Resolve with the sorted array of matched chats
  //       }
  //     };
  
  //     request.onerror = (event) => {
  //       reject(event.target.error); // Handle errors
  //     };
  //   });
  // };

  export const getChatsByToProperty = async (dbInstance, storeName, value) => {
    // console.log('getChatsByToProperty db', value);
    const tx = dbInstance.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
  
    // const indexTo = store.index("to"); // Use the "to" index
    const indexFrom = store.index("from"); // Use the "from" index
  
    const matchingChats = [];
  
    return new Promise((resolve, reject) => {
      // Validate value
      if (!value) {
        return reject(new Error("Invalid 'to' or 'from' value"));
      }
  
      // const rangeTo = IDBKeyRange.only(value);  // Ensure value is valid
      const rangeFrom = IDBKeyRange.only(value);  // Ensure value is valid
  
      // Fetch chats from 'to' index
      // const requestTo = indexTo.openCursor(rangeTo);
      const requestFrom = indexFrom.openCursor(rangeFrom);
  
      // Convert cursor requests into promises
      // const toPromise = new Promise((resolveTo, rejectTo) => {
      //   requestTo.onsuccess = (event) => {
      //     const cursor = event.target.result;
      //     if (cursor) {
      //       matchingChats.push(cursor.value);
      //       cursor.continue(); // Continue to next record
      //     } else {
      //       resolveTo(); // Resolve the promise when done
      //     }
      //   };
  
      //   requestTo.onerror = (event) => rejectTo(event.target.error);
      // });
  
      const fromPromise = new Promise((resolveFrom, rejectFrom) => {
        requestFrom.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            matchingChats.push(cursor.value);
            cursor.continue(); // Continue to next record
          } else {
            resolveFrom(); // Resolve the promise when done
          }
        };
  
        requestFrom.onerror = (event) => rejectFrom(event.target.error);
      });
  
      // Once both promises resolve, process the results
      Promise.all([fromPromise])
        .then(() => {
          // Sort the results by timestamp before resolving
          // console.log('matchingChats:', matchingChats); // Now this will log the populated chats
          const sortedChats = matchingChats.sort((a, b) => a.data.conversationTimestamp - b.data.conversationTimestamp);
          // console.log('sortedChats:', sortedChats);
          resolve(sortedChats);
        })
        .catch((error) => reject(error));
    });
  };  


  export const getPaginatedChats = async (dbInstance, storeName, toValue, limit, offset) => {
    const tx = dbInstance.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const index = store.index("to");
  
    const matchingChats = [];
    let counter = 0;
  
    return new Promise((resolve, reject) => {
      const request = index.openCursor(IDBKeyRange.only(toValue));
  
      request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          if (counter >= offset && counter < offset + limit) {
            matchingChats.push(cursor.value);
          }
          counter++;
          cursor.continue();
        } else {
          resolve(matchingChats.sort((a, b) => a.data.conversationTimestamp - b.data.conversationTimestamp));
        }
      };
  
      request.onerror = (event) => reject(event.target.error);
    });
  };
  

  export const getAllData = (db, storeName) => {
    return new Promise((resolve, reject) => {
      const transaction = db?.transaction([storeName], "readonly");
      const objectStore = transaction?.objectStore(storeName);
      const request = objectStore?.getAll();
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
      
    });
  };

  export const fetchChatsFromDB = async (db, selectedChat) => {
    // console.log('fetchChatsFromDB', selectedChat);
    
    if (!db) {
      console.error("Database is not available.");
      return [];
    }
  
    return new Promise((resolve, reject) => {
      // Start a transaction to read from the 'chats' object store
      const transaction = db?.transaction("chats", "readonly");
      const objectStore = transaction?.objectStore("chats");
      const chatsArray = [];
  
      // Open a cursor to iterate over all records in the object store
      const request = objectStore?.openCursor();
  
      request.onsuccess = (event) => {
        const cursor = event.target.result;
        // console.log('onsuccess cursor', cursor);
        
        if (cursor) {
          const record = cursor?.value;
          // console.log('onsuccess record', record);

          // Check if the record's id starts with the selectedChat
          if (record?.id?.includes(selectedChat)) {
            // console.log('onsuccess includes', record);
            chatsArray.push(record.data);
          }
  
          // Continue to the next record
          cursor?.continue();
        } else {
          // No more records
          resolve(chatsArray); // Resolve the promise with the retrieved chats
        }
      };
  
      request.onerror = (event) => {
        console.error("Error fetching chats from IndexedDB:", event.target.error);
        reject(event.target.error);
      };
    });
  };
  

  export const updateChatInStateAndDB = async (db, storeName, messageData, chats, setChats) => {
    if (!messageData) return;
  
    const id = messageData.id;
  
    // Update IndexedDB
    try {
      const tx = db.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
  
      // Remove old chat
      const deleteRequest = store.delete(id);
      deleteRequest.onsuccess = () => console.log(`Message with ID ${id} removed from IndexedDB`);
  
      const addRequest = store.put(messageData);
      addRequest.onsuccess = () => console.log(`Message with ID ${id} added to IndexedDB`);
  
      await tx.complete;
    } catch (error) {
      console.error("Error updating IndexedDB:", error);
    }
  };
  