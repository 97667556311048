import React, { useEffect, useState } from 'react';
import './sign-up.styles.css';
import eazybeLogoImg from "../../icons/eazybe-hand-logo.png";
import colorfulBgImg from "../../icons/colorful-bg.png";
import tickImg from "../../icons/tick.png";
import { TextField } from "@mui/material";
import OTPVerifyScreen from './otp-verification/OtpVerification.js';
import { API } from '../../index.js';
import { sessionActiveorNot } from '../../zustand/cloud/cloud.action.js';
import CloudNotsync from './cloud-notsynced/CloudNotsync.js';
import { toast } from 'react-toastify';
import AdminPermission from './admin-permission/AdminPermission.js';
import useSignUpStore from '../../services /store/useSignupStore.js';

/**
 * @module SignUpPage
 * @description Signup component
 * @returns {JSX.Element}
 */
const SignUpPage = () => {

  const [active, setActive] = useState(0);

  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [newUser, setNewUser] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [isAcceptedInviteUser, setIsAcceptedInviteUser] = useState(false);

  const [cloudActive, setCloudActive] = useState(false);

  const {checkUserExistence,userData} = useSignUpStore();

  function isEmail(mail) {
    if (/\S+@\S+\.\S+/.test(mail)) {
      return true;
    }
    return false;
  }

  const onClickVerifybtn = async () => {
    if (userEmail.includes(".magicaldeveloper")) {
      setUserEmail((prevState) => prevState.split(".magicaldeveloper")[0]);
      setSendEmail(false);
      if (isEmail(userEmail)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }

    // console.log('checking for email', userEmail);

    await checkUserExistence(userEmail);
  };

  useEffect(() => {
    // console.log('UserData', userData);

    if(!userData) return;
    
   const handleSignedUpUserData = async () => {
      // org type ka check lagna hai yaha
      // if(userData?.data?.org_type === 1 || userData?.data?.email === "shantanu@eazybe.com" || userData?.data?.email === "Sagar@eazybe.com" || userData?.data?.email === "sagar@eazybe.com"){
        if(true){
          // console.log('testing here');
        //checking cloud sync before login
        let checkCloudSync = await sessionActiveorNot(userData?.mobile); 
        // console.log('checkCloudSync', checkCloudSync);
        if(checkCloudSync?.status === "WORKING"){
          // console.log('cloud section working');
          
          setCloudActive(true);
          if (isValidEmail) {
            let resSignInUser = await API.signup.signInUser(userEmail,userMobile,userName);
            if (resSignInUser.status) {
              setNewUser(true);
            } else {
              setNewUser(false);
            }
            
            let resResendOtp = sendEmail ? await API.signup.resendOTP(userEmail) : { status: true };
            // console.log('resResendOtp', resResendOtp);
            if (resResendOtp.status) {
              setActive(1);
              toast.success("OTP sent successfully", "success");
            }
          }
        }
        else{
          setActive(3);
        }
      }
      else{
        setActive(4);
      }
    }

   handleSignedUpUserData();
  },[userData]);
 

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickVerifybtn();
    }
  };

  const handleChangeEmail = (e) => {
    setUserEmail(e.target.value);
    if (isEmail(e.target.value)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  useEffect(()=>{
    console.log('setActive', active);
    
  }, [active])

    return (
        <div className="signup-workspace-container flex flex-row">
          {active == 3 || active == 4 ? 
          (
            null
          ) : 
          (
            <div className="left-bg"></div>
          )}
          {active == 0 && (
            <div className="main-content-container flex flex-row">
              <div className="left-section-container flex flex-col">
                <div className="colorful-bg">
                  <img src={colorfulBgImg} className="colorful-bg-img" />
                </div>
                <div className="top flex flex-row">
                  <img src={eazybeLogoImg} className="eazybe-logo-img" />
                  <p className="txt">
                    EazyBe<span className="red-dot">.</span>
                  </p>
                </div>
                <div className="middle flex flex-col">
                  <h4>Level up your sales team Performance.</h4>
                  <p>Thousands of teams worldwide are using Eazybe Workspace</p>
                </div>
                <div className="bottom">
                  <ul>
                    <li>Improve the visibility into your sales team</li>
                    <li>Get all customer conversations in one place</li>
                    <li>Smart Data Analysis to understand team’s performance</li>
                  </ul>
                </div>
              </div>
              <div className="right-section-container flex flex-col">
                {/* <div className="sign-in-btn-container flex flex-row">
                  <p className="font-medium">Already have an account?</p>
                  <Link
                    to="/workspace/signin"
                    className="sign-in-btn cursor-pointer font-medium"
                  >
                    Sign in
                  </Link>
                </div> */}
                <div className="form-content flex flex-col">
                  {isAcceptedInviteUser ? (
                    <div className="accepted-invite-container flex flex-col">
                      <div className="top flex flex-row">
                        <h4>Invite Accepted</h4>
                        <img src={tickImg} alt="tick-img" />
                      </div>
                      <div className="bottom flex flex-col mt-2">
                        <p>You’ve successfully joined the organisation.</p>
                        <p>Please login to continue</p>
                      </div>
                    </div>
                  ) : (
                    <h4 className="header-txt">
                      Sign up/Sign in to Eazybe workspace
                    </h4>
                  )}
                  <div className="middle flex flex-col">
                    <div className="email-input-container">
                      <TextField
                        value={userEmail}
                        onChange={handleChangeEmail}
                        className="email-input"
                        size="small"
                        label="Enter Email"
                        placeholder="email@mail.com"
                        onKeyPress={handleOnKeyPress}
                        autoFocus={true}
                        autoComplete={"true"}
                      />
                      <button
                        className="verify-btn"
                        onKeyPress={handleOnKeyPress}
                        onClick={onClickVerifybtn}
                      >
                        Verify
                      </button>
                    </div>
                    {/* <p className="or-txt font-medium">OR</p> */}
                    {/* <div style={{ display: "none" }}>
                      <GoogleLogin
                        className="google-login-btn-workspace"
                        clientId={CLIENT_ID_EAZYBE}
                        buttonText="Login"
                        onSuccess={onGooglelogin}
                        onFailure={onGoogleFailure}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                    <div
                      onClick={handleGoogleLogin}
                      className="signup-google flex flex-row cursor-pointer"
                    >
                      <img src={googleLogoImg} className="google-logo" />
                      <p className="txt font-medium">Continue with Google</p>
                    </div> */}
                  </div>

                  <div className="tnc-container font-medium">
                    By signing up you agree to our{" "}
                    <span className="text-blue-500 cursor-pointer">
                      Terms & Condition
                    </span>{" "}
                    and{" "}
                    <span className="text-blue-500 cursor-pointer">
                      Privacy Policy
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {active == 1 ? (
            <OTPVerifyScreen
              confirmTxt={"Continue"}
              userEmail={userEmail}
              setActive={setActive}
            />
          ) : null}
          {/* {active == 2 ? (
            <h1>Cloud sync is not enabled for this user</h1>
          ) : null} */}
          {active == 3 ? (
            <>
              <CloudNotsync sendEmail={sendEmail} setActive={setActive} />
            </>
          ) : null}
          {active == 4 ? (
            <>
              <AdminPermission setActive={setActive} />
            </>
          ) :
          (
            null
          )
          }
        </div>
      );
}

export default SignUpPage;