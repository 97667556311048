import { useState } from "react";
import { APP_URL_PREFIX, getUserMobile, getUserOrgId, getWorkspace_Id } from "../../utils/api";
import { getWorkspaceId } from "../../util";
import { json } from "react-router-dom";


/**
 * saves eazybe webhook data in local storage
 * @param {*} data
 */
// export const setEazybeWebhookDataInLocal = (data) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         await chrome.storage.local.set({
//           [LOCALSTORETYPES.EAZYBE_WEBHOOK_DATA]: data,
//         });
//         resolve(true);
//       } catch (e) {
//         console.error("Failed to set eazybe webhook data in local", e);
//       }
//     });
//   };
  /**
   * get eazybe webhook data from local storage
   * @param {*} data
   */
//   export const getEazybeWebhookDataFromLocal = () => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         let result = await chrome.storage.local.get([
//           LOCALSTORETYPES.EAZYBE_WEBHOOK_DATA,
//         ]);
//         let response = result[LOCALSTORETYPES.EAZYBE_WEBHOOK_DATA];
//         resolve(response);
//       } catch (e) {
//         console.error("Failed to get  eazybe webhook data from local", e);
//         reject(e);
//       }
//     });
//   };

/**
 * send eazybe webhook data
 * @param {*} userWorkspaceId
 * @param {*} data
 * @returns
 */

export const getEazybeWebhookData = async (userInitialData) => {
      try {
        const res = await fetch(
          `https://eazybe.com/api/v1/whatzapp/webhook/endpoints?org_id=${getUserOrgId(userInitialData)}`)
          const response = res.json();
        //   setEazybeWebhookDataInLocal(response);
          return response;

      } catch (err) {
        console.error("error while getting eazybe webhook data", err);
      }
};

export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onabort = reject;
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }


/**
 * send eazybe webhook message data
 * @param {*} userWorkspaceId
 * @param {*} data
 * @returns
 */

export const sendEazybeWebhookMessageData = async (request) => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

      // If authToken exists, add it to the headers
      // if (authToken) {
      //   myHeaders.append("Authorization", `Bearer ${authToken}`);
      // } 

      console.log('sendEazybeWebhookMessageData', request);
      
      const values = Object.values(request.msgObject);

      if(!values?.length)  return null;

      for (let array of values) {
        for (let chat of array) {
          if (chat.blob) {
    
            try{
              const fetchFile = await fetch(chat.blob)
              chat.File = await blobToBase64(await fetchFile.blob());
              
            } catch(error) {
              console.error("Unable to downlaod blob", error)
              chat.File = null;
            }
          }
        }
      }

        // const raw = JSON.stringify(convertPayload(request.msgObject));
        const raw = convertPayload(request.msgObject);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(raw)
        };

        console.log('requestOptions', requestOptions);

        const endPoint = request.endPoint

        const res = await fetch(
          `${endPoint}`,
          requestOptions
        )
        
        if(res.ok){
          return true;
        }
        else {
          return null;
        }
        
      } catch (err) {
        console.error("error while send eazybe webhook message data", err);
        return null;
      }
};

/**
 *
 * @returns To check if webhooks is integrated or not
 */
export const isWebhooksIntegrated = (userInitialData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const integrationsData = userInitialData?.integrations;

      if (integrationsData?.webhooks) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (e) {
      console.error("Failed to get if webhooks is integrated or not", e);
    }
  });
};

/**
 * @function getWebhookSyncInfo
 * @description To get Webhoooks sync info
 * @param {*} userWorkspaceId
 * @param {*} request
 * @returns {void}
 */ 

export const getWebhookSyncInfo = (userInitialData) => {
  return new Promise(async (resolve, reject) => {
    try {

     let createdAt 
     await getUserOrgId(userInitialData)
      .then(async(userOrgId) => {
        await getEazybeWebhookData(userOrgId).then((response) => {
          createdAt = response?.data?.createdAt;
        });
      })
      
      let obj = {
        last_sync : "1947-05-27T11:00:30.000Z",
        sync_started_at : createdAt
      }

      fetch(`${APP_URL_PREFIX}crmlastsync?workspace_id=${getWorkspace_Id(userInitialData)}`)
        .then((res) => res.json())
        .then(async (response) => {
          if(response?.result){
            if(response?.data?.webhook_ext){
              obj.last_sync = response?.data?.webhook_ext
              resolve(obj);
            }
            else{
              await sendWebhookSyncInfo(getWorkspaceId())
              obj.last_sync = createdAt
              resolve(obj)
            }
          }else{
            await sendWebhookSyncInfo(getWorkspaceId())
            obj.last_sync = createdAt
            resolve(obj)
          }
        });
    } catch (err) {
      console.error("error while getting Webhook  sync info ", err);
    }
  });
};

/**
 * @function sendWebhookSyncInfo
 * @description To send Webhoooks sync info
 * @param {*} userWorkspaceId
 * @param {*} request
 * @returns {Promise<void>}
 */ 

export const sendWebhookSyncInfo = (workspace_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('sendWebhookSyncInfo');
      
      let newSyncDate = new Date().toISOString()
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      const raw = JSON.stringify({
        "workspace_id": workspace_id, //await getWorkspaceId(userInitialData),
        "last_sync": newSyncDate,
        "crm_name": "webhook_ext"
      });
      
      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      try {
        const response = await fetch(`${APP_URL_PREFIX}crmlastsync`, requestOptions);
        const result = await response.text();
        if(result){
          resolve(true)
        }else{
          reject(false)
        }
      } catch (error) {
        console.error("error while getting Webhook  sync info ", error);
        reject(false)
      };
    } catch (err) {
      console.error("error while getting Webhook  sync info ", err);
      reject(false)
    }
  });
};


export function convertPayload(inputData) {
  const output = {};

  for (const key in inputData) {
    const messages = inputData[key];
    output[key] = messages.map(message => {
      return {
        MessageId: message._data.id.id,
        Message: message.body,
        Chatid: message.from,
        File: message.hasMedia ? message.body : null, // File would be the body in case of media; null if not
        FileName: null, // FileName is null for this example as no file name is available in the provided input.
        Ack: message.ack,
        Datetime: message.conversationTimestamp * 1000, // Convert Unix timestamp to milliseconds
        Date: new Date(message.conversationTimestamp * 1000).toISOString(), // Convert timestamp to ISO string
        Direction: message.fromMe ? "OUTGOING" : "INCOMING",
        SentById: message.from,
        ChannelName: ".", // ChannelName is assumed here as no channel info is provided in input
        ChannelId: "1:1", // Assumed ChannelId here for the example
        SentByNumber: message.from,
        sentByUserId: message.from,
        CreatedByUser: message.author, // Assuming author field is the user creating the message
        senderName: "." // Default name as senderName is not provided in the input data
      };
    });
  }

  return output;
}