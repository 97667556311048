/**
 * @function getEazybeWebhookData
 * @description get webhook url data
 * @param {*} userWorkspaceId
 * @param {*} data
 * @returns {Promise<any>}
 */

import useSignUpStore from "../store/useSignupStore";

export const getEazybeWebhookData = async () => {
    try {
      const userOrgId = useSignUpStore.getState().userData?.org_id
      if(!userOrgId) return;

    const res =  await fetch(
        `https://eazybe.com/api/v1/whatzapp//webhook/endpoints?org_id=${userOrgId}`)
        const response = await res.json();
          console.log("webhook", response);
          // setEazybeWebhookDataInLocal(response);
          // localStorage.setItem("")
          if(response?.data) {
            localStorage.setItem("isWebhookEnabled", response.data?.status === 1 ? true : false);
            if(response.data?.status) {
                localStorage.setItem("webhook_data", JSON.stringify(response.data));
            }
           } else {
               localStorage.setItem("isWebhookEnabled", false);
           }
           
          return response;
    } catch (err) {
      console.error("error while getting eazybe webhook data", err);
      return null;
    }
};

/**
 * @function uploadToImgBB
 * @description for testing image upload.
 * @param {string} base64 
 * @returns {object} from imgBB
 */
export const uploadToImgBB = async (base64) => {
    try {
            const apiKey = 'ed17b23a669f0215b4141bb2d71dfa70';
        const expiration = 600;
        
        const formData = new FormData();
        formData.append('image', base64);
        
        const res = await fetch(`https://api.imgbb.com/1/upload?expiration=${expiration}&key=${apiKey}`, {
          method: 'POST',
          body: formData,
        })
        const response = await res.json();
        return response;
         
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
    }

/**
 * @function getWebhookLastSyncTime
 * @async
 * @description fetches webhook last sync time.
 * @param {string} workspaceId 
 * @returns {Promise<WebhookLastSyncResponse> | null}
 */
export const getWebhookLastSyncTime = async (workspaceId) => {
  try {
    const res = await fetch(`https://eazybe.com/api/v1/whatzapp/crmlastsync?workspace_id=${workspaceId}`);
    const response = await res.json();
    return response;
  } catch (error) {
    console.error("Failed to get webhook last sync time", error);
    return null;
  }
}

/**
 * @function updateWebhookSyncTime
 * @async
 * @description updates webhook sync time on server
 * @param {UpdateWebhookRequest} request
 * @returns {Promise<DefaultResponse> | null}
 */
export const updateWebhookSyncTime = async (request) => {
  try {
    const res = await fetch(`https://eazybe.com/api/v1/whatzapp/crmlastsync`, {
      method : "PUT",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    });

    const response = await res.json();
    return response;
  } catch (error) {
    console.error("Failed to get webhook last sync time", error);
    return null;
  }
}


  /**
 * send eazybe webhook message data
 * @function sendEazybeWebhookMessageData
 * @description upload messages data to webhook url which is fetched from {@link getEazybeWebhookData}
 * @param {object} request
 * @param {string} workspaceId
 * @returns {Promise<any>}
 */

  export const sendEazybeWebhookMessageData = async (workspaceId,request) => {
    try {
      // console.log("sendEazybeWebhookMessageData request", request);

      const authToken = request?.data?.authToken;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

    // If authToken exists, add it to the headers
    if (authToken) {
      myHeaders.append("Authorization", `Bearer ${authToken}`);
    }

      let raw = JSON.stringify(request.data.msgObject);
      // let raw = request.data.msgObject
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };
      let endPoint = request.data.endPoint
      const res = await fetch(
        `${endPoint}`,
        requestOptions
      )
  
      if(res.ok){
        if(workspaceId) {
          const updatePayload = {
            workspace_id : workspaceId,
            last_sync: new Date().toJSON(),
            crm_name: "webhook"
          }
          updateWebhookSyncTime(updatePayload);
        }
        
          return true;
      } else {
          return false;
      }
      
    } catch (err) {
      console.error("error while send eazybe webhook message data", err);
      return false;
    }
};