import './App.css';
import WhatsApp from './pages/whatsAppUI/WhatsApp';
import i18n from './i18n.js';
import { useEffect, useState } from 'react';
import SignUpPage from './pages/sign-up/Signup.js';
import useStorage from './services /store/useSignupStore.js';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSignUpStore from './services /store/useSignupStore.js';

function App() {
  const languagesPresent = ['en', 'es', 'hi', 'id', 'pt', 'pt_br', 'pt_pt'];
  useEffect(() => {
    let preferredLanguage = localStorage.getItem("preferredLanguage");
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
      // i18n.changeLanguage("en");
    } else {
      let language = navigator.language.slice(0, 2);
      // const languagesPresent = ['en', 'es', 'hi', 'id', 'pt']
      if (languagesPresent.includes(language)) {
        i18n.changeLanguage(language);
        // i18n.changeLanguage("en");
      } else {
        i18n.changeLanguage("en");
      }
    }
  }, []);

  const isUserLoggedIn = useSignUpStore((state) => state.isUserLoggedIn);

  return (
    <>
      <Routes>
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/whatsapp" element={isUserLoggedIn ? <WhatsApp /> : <Navigate to="/signup" />} />
        <Route path="/" element={isUserLoggedIn ? <Navigate to="/whatsapp" /> : <Navigate to="/signup" />} /> 
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
