import { Button } from "@mui/material";
import { Cable, Download, MoreVertical, Search, X } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ChatsGroupUI from "../chats-group-UI/ChatsGroupUI";

const TopBar = ({setGroupIndividualProfile, setSelectedChat, enableSearchBar, setEnableSearchBar, chats, setSearchQuery, chatDetails}) => {
  // console.log("TopBar", {setSelectedChat, enableSearchBar, setEnableSearchBar, chats, setSearchQuery, chatDetails});
  // console.log("Top Bar", chatDetails?.data?.name , chatDetails?.data?.groupMetadata?.subject);
  
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  // Toggle the dropdown
  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  // Close the dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Attach event listener to document
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const addNumber = () => {
    let numbers = chatDetails?.data?.groupMetadata?.participants.map((participant) => (
      `+${participant?.id?.user}`
    ));
  
    // If numbers exist, proceed
    if (numbers && numbers.length > 0) {
      let limitedNumbers = numbers?.slice(0, 4); // Get the first 4 numbers
  
      // Check if there's a 5th number to partially display
      let halfNumber = numbers[4]?.slice(0, Math.ceil(numbers[4]?.length / 2));
  
      return (
        <span>
          {limitedNumbers.join(', ')}
          {numbers.length > 4 && `, ${halfNumber}...`} {/* Add half of the 5th number if it exists */}
        </span>
      );
    }
  };
  
  const [searchTerm, setSearchTerm] = useState("");

  const filteredChats = useMemo(() => {
    if(chats?.length > 0 && Array.isArray(chats) && searchTerm?.length > 0){
      return chats?.filter((chat) =>
        chat?.data?.body?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
    }
  }, [chats, searchTerm]);
  setSearchQuery(filteredChats);
  // console.log('filteredChats', filteredChats);

  const [mediaChats, setMediaChats] = useState([]);

  useEffect(()=>{
    // console.log('chats', chats);
  }, [])
  
  
  const { t } = useTranslation();
  // console.log('chatDetails', chatDetails);

  const [isClicked, setIsClicked] = useState(false);

  return (
    <div ref={dropdownRef} className="p-4 bg-[#EFF2F5] dark:bg-[#1F2C33] flex justify-between items-center">
      <div onClick={() => {
            setIsClicked(prevState => !prevState); 
            setGroupIndividualProfile(false);
          }}
          className="flex items-center cursor-pointer">
        {
          chatDetails?.profilePic?.length > 0 ? 
          (
            <img src={chatDetails?.profilePic} className="h-[40px] w-[40px] rounded-full" />
          ) 
          : 
          (
            <span aria-hidden="true" data-icon="default-user" class="">
              <svg
                viewBox="0 0 212 212"
                height="40"
                width="40"
                preserveAspectRatio="xMidYMid meet"
                class="xh8yej3 x5yr21d"
                version="1.1"
                x="0px"
                y="0px"
                enable-background="new 0 0 212 212"
              >
                <title>default-user</title>
                <path
                  fill="#DFE5E7"
                  class="background"
                  d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                ></path>
                <g>
                  <path
                    fill="#FFFFFF"
                    class="primary"
                    d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                  ></path>
                  <path
                    fill="#FFFFFF"
                    class="primary"
                    d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                  ></path>
                </g>
              </svg>
            </span>
          )
        }
        <div className="flex flex-col gap-1">
          <span className="text-[1rem] ml-4 text-gray-800 dark:text-gray-200">
            {chatDetails?.data?.name || chatDetails?.data?.groupMetadata?.subject || chatDetails?.data?.id.split("@")[0]}
          </span>

          <span className="text-[0.8rem] ml-4 text-gray-800 dark:text-gray-200">
            {addNumber()}
          </span>
        </div>
      </div>
      <div className="flex gap-4 space-x-4">
        <span className={`flex w-12 h-6 flex justify-center rounded-full relative items-center gap-1 cursor-pointer ${isOpen ? 'bg-[#D8DBDF]' : ''}`}
        onClick={handleClick}>
          <span
            aria-hidden="true"
            data-icon="video-call"
            class="text-[#A1ABB2]"
          >
            <svg
              viewBox="0 0 24 24"
              height="24"
              width="24"
              preserveAspectRatio="xMidYMid meet"
              class=""
              fill="none"
            >
              <title>video-call</title>
              <path
                d="M3.27096 7.31042C3 7.82381 3 8.49587 3 9.84V14.16C3 15.5041 3 16.1762 3.27096 16.6896C3.5093 17.1412 3.88961 17.5083 4.35738 17.7384C4.88916 18 5.58531 18 6.9776 18H13.1097C14.502 18 15.1982 18 15.7299 17.7384C16.1977 17.5083 16.578 17.1412 16.8164 16.6896C17.0873 16.1762 17.0873 15.5041 17.0873 14.16V9.84C17.0873 8.49587 17.0873 7.82381 16.8164 7.31042C16.578 6.85883 16.1977 6.49168 15.7299 6.26158C15.1982 6 14.502 6 13.1097 6H6.9776C5.58531 6 4.88916 6 4.35738 6.26158C3.88961 6.49168 3.5093 6.85883 3.27096 7.31042Z"
                fill="currentColor"
              ></path>
              <path
                d="M18.7308 9.60844C18.5601 9.75994 18.4629 9.97355 18.4629 10.1974V13.8026C18.4629 14.0264 18.5601 14.2401 18.7308 14.3916L20.9567 16.3669C21.4879 16.8384 22.3462 16.4746 22.3462 15.778V8.22203C22.3462 7.52542 21.4879 7.16163 20.9567 7.63306L18.7308 9.60844Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <span
            aria-hidden="true"
            data-icon="chevron"
            class="inline-block rotate-90 text-[#A1ABB2]"
          >
            <svg
              viewBox="0 0 30 30"
              height="10"
              preserveAspectRatio="xMidYMid meet"
              class=""
              x="0px"
              y="0px"
            >
              <title>chevron</title>
              <path
                fill="currentColor"
                d="M11,21.212L17.35,15L11,8.65l1.932-1.932L21.215,15l-8.282,8.282L11,21.212z"
              ></path>
            </svg>
          </span>
        </span>
        <Search onClick={()=> setEnableSearchBar(!enableSearchBar)} className="w-5 h-5 text-gray-600 dark:text-gray-300" />
        {enableSearchBar && 
          <div className="flex bg-white pl-5 pr-5 pt-2 pb-2 rounded-md flex-col gap-2 absolute top-[3rem] z-[100] left-[40rem] h-[6.6rem]">
            <span className="text-gray-500">Search Message</span>
            <div className="flex items-center">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t("Search_or_start_new_chat.message")}
                className="w-[30vw] h-[3rem] pl-10 bg-[#EFF2F5] dark:bg-gray-700 rounded-lg text-gray-800 dark:text-gray-200 focus:outline-none"
              />
              <Button
                variant="destructive"
                size="icon"
                // className="h-[5rem] w-[5rem]"
                onClick={()=> setEnableSearchBar(false)}
              >
                <X className="h-6 w-6" />
              </Button>
            </div>
          </div>
        }
        <MoreVertical className="w-5 h-5 text-gray-600 dark:text-gray-300" />
      </div>
      {
        isClicked && (
          <ChatsGroupUI setIsClicked={setIsClicked} isClicked={isClicked} chatDetails={chatDetails} setSelectedChat={setSelectedChat} />
        )
      }
        {isOpen && (
            <main className="flex-1 absolute top-10 right-[6rem] z-50 overflow-y-auto p-4 flex flex-col items-center justify-center">
                {/* Dropdown/Popup Message */}
                <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full mb-8">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">Make calls with your phone app</h2>
                <p className="text-gray-600 mb-4">Download WhatsApp on your phone to start making calls.</p>
                <button className="bg-green-600 text-white py-2 px-4 rounded-full flex items-center justify-center w-full hover:bg-green-700 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">
                <Download className="w-5 h-5 mr-2" />
                <a className="no-underline text-white" target="_blank" href="https://www.whatsapp.com/download">
                    Get the app
                </a> 
                </button>
            </div>
            </main>
        )}
    </div>
  );
};

export default TopBar;
