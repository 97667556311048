import React from "react";
import "../sign-up.styles.css";
import { Navigate, useNavigate } from "react-router-dom";

const AdminPermission = ({setActive}) => {

  return (
    <div className="h-[100vh] w-full flex items-center justify-center bg-[#fcf5eb]">
      <div className="flex min-h-[400px] max-w-4xl items-center justify-center bg-white p-4 border border-black p-10 rounded-lg">
      <div className="w-full max-w-md items-center">
        {/* Shield Icon */}
        <div className="flex items-center gap-4">
          <div className="mx-auto h-24 w-24">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              className="h-full w-full"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
              <path d="M12 8v4" />
              <circle cx="12" cy="14" r="1" />
            </svg>
          </div>

          {/* Text Content */}
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">Permission required</h1>
            <p className="text-gray-600">Ask your admin you give permission to scan WhatsApp</p>
          </div>
        </div>

        {/* Button */}
        <button
          className="w-full mt-10 rounded-lg bg-[#6366f1] px-6 py-3 text-lg font-medium text-white transition-colors hover:bg-[#4f46e5] focus:outline-none focus:ring-2 focus:ring-[#6366f1] focus:ring-offset-2"
          onClick={() => {
            setActive(0)
          }}
        >
          Okay
        </button>
      </div>
      </div>
    </div>
  );
};

export default AdminPermission;
