import Chatter from "./components/UI/chatterBox/Chatter";
import useSignUpStore from "./services /store/useSignupStore";
import useStorage from "./services /store/useSignupStore";

/**
 * @function showEazybe
 * @description makes eazybe-root element visible.
 * @returns {void}
 */
export const showEazybe = () => {
    const element = document.getElementById("eazybe-root");
    if (!element) return;
    element.style.display = "block";
}

/**
 * @function hideEazybe
 * @description makes eazybe-root element invisible.
 * @returns {void}
 */
export const hideEazybe = () => {
    const element = document.getElementById("eazybe-root");
    if (!element) return;
    element.style.display = "none";
}

/**
 * @function getLoggedInUserMobile
 * @description to get logged in user mobile number from local storage.
 * @returns {string} mobile number of the logged in user.
 */
export const getLoggedInUserMobile = () => {
    if (!localStorage.getItem("last-wid-md")) {
      return localStorage.getItem("last-wid")?.split("@")[0].replace('"', "");
    } else {
      return localStorage.getItem("last-wid-md")?.split(":")[0].replace('"', "");
    }
}

/**
 * @function getWorkspaceId
 * @description to get logged in user workspace id from storage.
 * @returns {string} mobile number of the logged in user.
 */
export const getWorkspaceId = () => {
  return useSignUpStore.getState().userData.id;
}

/**
 * @function getChatId
 * @description this function is used to get the chatter data of the chat id.
 * @param {*} chatId - chat id of the chatter
 * @returns {object} chatter data related to the chat id 
 */
export const getChatId = (chatId) => {
    try {
      if(!chatId || typeof chatId !== "string" || !window.WPP) return null;
      const item = window.WPP.whatsapp.ChatStore.get(chatId);
       return item;
    } catch (error) {
    // console.error(`Invalid Wid ${chatId}`,error );
      return null;
    }
  }
/**
 * @function capitalizeString
 * @description capitalize the string
 * @param {string} inputString 
 * @returns {string}
 */
export const capitalizeString = (inputString) => {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

/**
 * Retrieves the chat models from the WhatsApp Web client.
 *
 * This function attempts to access the chat models from the WhatsApp Web client's `ChatStore`.
 * If successful, it returns an array of chat models. If any error occurs during the process,
 * it logs the error to the console and returns an empty array.
 *
 * @async
 * @function getChatModel
 * @returns {Promise<Object[]>} A promise that resolves to an array of chat models. 
 *                              If an error occurs, it resolves to an empty array.
 */
export const getChatModel = async () => {
  try{
   const model = window.WPP?.whatsapp?.ChatStore?._models || []
   const chatModels = [...model];
   return chatModels;
  } catch(e) {
   console.log("Failed to get model", e);
   return [];
  }
 };

/**
 * Retrieves an array of chat IDs from the chat models filtered by a specified time.
 *
 * This function first fetches the chat models using the {@link getChatModel} function.
 * It then filters the chat models based on the provided time, if any.
 * Finally, it maps the filtered chat models to their serialized IDs and returns them.
 *
 * @async
 * @function getChatIdArrayFromModelAfterTime
 * @param {number} time - The time in milliseconds. Only chat models with a timestamp
 *                           greater than this time will be included in the result.
 *                           If not provided, all chat models are included.
 * @returns {Promise<string[]>} A promise that resolves to an array of chat IDs.
 *                              If no chat models are found or an error occurs, it resolves to an empty array.
 */
 export const getChatIdArrayFromModelAfterTime = async (time) => {
  if(!time) {
    return [];
  }

  const chatModel = await getChatModel();

  if (!chatModel) return [];

  return chatModel
    .filter(item => {
      if(item.isGroup) {
        if(item.canSend) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .filter(item => (!time || item.t * 1000 > time))
    .map(item => item.id);
};


/**
 * Creates an object containing chat messages for specified chatters within a date range.
 *
 * This function processes an array of chatters, retrieves messages for each chatter within
 * the specified date range, and constructs an object containing these messages. It also keeps
 * track of the latest message time across all chatters.
 *
 * @async
 * @function createChatterMessagesObject
 * @param {string[]} chatterArray - An array of chatter IDs.
 * @param {string} userMobile - The mobile number of the user.
 * @param {number} startDateInMillis - The start date in milliseconds. If provided, only messages after this date will be included.
 * @param {number} endDateInMillis - The end date in milliseconds. If provided, only messages before this date will be included.
 * @returns {Promise<{syncObject: Object, lastSyncedMessageTime: ?number}>} A promise that resolves to an object containing:
 * - `syncObject`: An object mapping each chatter ID to an array of messages.
 * - `lastSyncedMessageTime`: The timestamp of the last synced message across all chatters, or null if no messages were synced.
 */
export const createChatterMessagesObject = async (chatterArray, userMobile, startDateInMillis, endDateInMillis) => {
  try {
    if (!chatterArray?.length || !startDateInMillis) {
      return { syncObject: {}, lastSyncedMessageTime: null };
    }

    let syncObject = {}
    let lastSyncedMessageTime = null;

    // console.log("HUBSPOT CHATTER", chatterArray)

    for (let item of chatterArray) {
      if (item) {
        let chatter = new Chatter(item, userMobile)
        if (!syncObject[item]) {
          let messages = []
          if (startDateInMillis && endDateInMillis) {
            messages = await chatter.messagesBetweenDates(startDateInMillis, endDateInMillis, 20)
          } else {
            messages = await chatter.messagesAfterDate(startDateInMillis, 20)
          }

          // console.log("messages",messages);

          if (messages?.length) {
            syncObject[item] = messages

            let lastMessageTime = chatter.lastMessageTime

            if (lastMessageTime) {
              if (!lastSyncedMessageTime) {
                lastSyncedMessageTime = lastMessageTime
              } else if (lastMessageTime > lastSyncedMessageTime) {
                lastSyncedMessageTime = lastMessageTime
              }
            }

            // Clear the memory used by the chunk of messages
            messages = null;
          }
          // Clear the memory used by the 'chatter' object
          chatter = null;
        }
      }
    }

    // console.log("syncObject",syncObject);

    return { syncObject, lastSyncedMessageTime };

  } catch (err) {
    console.error("Failed to create chatter object", err);
    return null;
  }

}

export function filterRecentMessages(chatter, lastSync) {
  // console.log('filterRecentMessages', {chatter, lastSync});
  
  const lastSyncDate = new Date(lastSync);

  // Filter out messages where the timestamp is greater than lastSync
  return chatter.filter((message) => {
    const messageTimestamp = new Date(message?.conversationTimestamp * 1000); // Convert to milliseconds
    // console.log("timings checking", messageTimestamp, lastSyncDate);
    return messageTimestamp > lastSyncDate;
  });
}

export function filterRecentChats(chatter, lastSync) {
  // console.log('filterRecentChats', {chatter, lastSync});
  
  const lastSyncDate = new Date(lastSync);

  // Filter out messages where the timestamp is greater than lastSync
  return chatter.filter((message) => {
    const messageTimestamp = new Date(message?.data?.conversationTimestamp * 1000); // Convert to milliseconds
    // console.log("timings checking", messageTimestamp, lastSyncDate);
    return messageTimestamp > lastSyncDate;
  });
}