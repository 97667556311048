import { create } from "zustand";
import { fetchChatOfChatterOrGroup, fetchWhatsappChatter, getProfilePicture, sessionActiveorNot } from "../zustand/cloud/cloud.action";
import { data } from "autoprefixer";

const chatterStore = create((set)=> ({
    //initialization
    data: null,
    profilePicture: {},
    loading: false,
    error: null,

    //actions
    fetchChatters: async () => {
        set({ loading: true, error: null });
        try {
            const response = await fetchWhatsappChatter();
            console.log('fetchChatters', response);
            
            set({ data: response, loading: false });
            return response; // Return the response
        } catch (error) {
            set({ error: error?.message, loading: false });
            throw error; // Rethrow the error to be handled in the calling function
        }
    },
    

    fetchChats: async(sessionName, chatId) => {
        set({loading: true, error: null});
        try {
            const response = await fetchChatOfChatterOrGroup(sessionName, chatId);
            console.log('fetchChats', response);
            
            set({data: response.data, loading: false})
        } catch (error) {
            set({error: error?.message, loading: false})
        }
    },

    sessionActiveValidation: async(sessionName, chatId) => {
        set({loading: true, error: null});
        try {
            const response = await sessionActiveorNot(sessionName);
            console.log('sessionActiveValidation', response);
            
            set({data: response.data, loading: false})
        } catch (error) {
            set({error: error?.message, loading: false})
        }
    },

    getProfilepicture: async(sessionName, session, chatId) => {
        set({loading: true, error: null});
        try {
            if (!session || !sessionName || !chatId) return;
            
            console.log("FIRST TIME", sessionName, session,chatId)
            const response = await getProfilePicture(sessionName, session, chatId);
            console.log('sessionActiveValidation', response);
            
            set(state => ({
                profilePicture: {
                ...state.profilePicture,
                [chatId]: response?.profilePictureURL
            }, 
            loading: false
        }))
        } catch (error) {
            set({error: error?.message, loading: false})
        }
    }

}));

export default chatterStore;