import  React,{ useState, useEffect } from "react";
import "./otpverifyscreen.css";
import eazybeLogoImg from "../../../icons/eazybe-hand-logo.png";
import diverseTeamImg from "../../../icons/diverse-team.png";
// import {  useHistory } from "react-router-dom";
// import { ArrowBackIos } from "@material-ui/icons";
// import { useTranslation } from "react-i18next";
import OTPInput from "otp-input-react";
import { API, Util } from "../../..";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/**
 * @module OTPVerifyScreen
 * Email Verification screen through OTP
 * @param {*} param0 
 * @returns {JSX.Element}
 */
const OTPVerifyScreen = ({
  setActive,
  userEmail,
  confirmTxt,
}) => {
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState();
  const [requiredOtpLimitLengthMatched, setRequiredOtpLimitLengthMatched] = useState(false);


  useEffect(() => {
    if (otp?.length == 6) {
      setRequiredOtpLimitLengthMatched(true);
    } else {
      setRequiredOtpLimitLengthMatched(false);
    }
  }, [otp]);


  const navigate = useNavigate();
  const handleUserExistance = async (userExistenceData) => {
    try {
      // console.log("userExistenceData", userExistenceData );
    
    if (userExistenceData?.type) {
      toast.success("Logged in successfully");
      
      if (userExistenceData?.data && userExistenceData.data.callyzer_user_mappings.length) {
        // console.log("USER EXISTS", userExistenceData);
       
        const userData = userExistenceData.data.callyzer_user_mappings[0];
        const firstOrg = userData.callyzer_organization;

      // console.log("firstOrg", firstOrg);
      let firstOrgId = firstOrg.id;
      let firstOrgCode = firstOrg.org_code;
      let firstOrgName = firstOrg.org_name;
      let userRoleId = userData.role_id;

      localStorage.setItem("currentOrgId", firstOrgId);
      localStorage.setItem("currentOrgCode", firstOrgCode);
      localStorage.setItem("currentOrgName", firstOrgName);
      localStorage.setItem("userEmail", userEmail);
      localStorage.setItem("userRoleId", userRoleId);
      localStorage.setItem( "workspaceId", userExistenceData?.data.id);

      setActive("Details");
      Util.hideEazybe();
      navigate("/whatsapp")
      return;
     }
        }

        console.log("USER DOESN'T EXISTS", userExistenceData);
        localStorage.setItem("userEmail", userEmail);
        setActive(2);
    } catch(error) {
        console.error("Failed in handling user existance",error);
    }
  }

    const onChangeOtpHandler = (otp) => {
      setOtp(otp);
      // console.log(otp);
    };

    const onClickConfirm = async () => {
      if (otp?.length == 6) {
        let resVerifyOTP;
        if (otp != "maagic") {
          resVerifyOTP = await API.signup.verifyOTP(userEmail,otp);
        }
        if (otp == "maagic" || resVerifyOTP.status) {
          setOtpVerified(true);
          const response = await API.signup.checkIfUserExists(userEmail);
          response && handleUserExistance(response);
        }
        else {
          setOtp("");
          toast.success("Invalid OTP");
        }
      }
    };

    function handleKeyDown(e) {
      if (e.key === "Enter") {
        onClickConfirm()
      }
    }

    const onClickResendOTP = async () => {
      await API.signup.resendOTP(userEmail);
      toast.success("OTP sent successfully");
    };


  return (
    // <div className="otp-verify-screen-container d-flex-row">
      
    //   {/* LEFT SECTION */}

    //   <div className="left-section-container d-flex-col">
    //     <div className="top d-flex-row">
    //       <img src={eazybeLogoImg} className="eazybe-logo-img" />
    //       <p className="txt">
    //         EazyBe<span className="red-dot">.</span>
    //       </p>
    //     </div>
    //     <h4>Get productive with your team now </h4>
    //     <img src={diverseTeamImg} className="team-img" />
    //   </div>

    //   <div className="right-section-container d-flex-col">
    //     {/* <div className="sign-in-btn-container d-flex-row">
    //       <p className="fw-500">Existing user ?</p>
    //       <Link
    //         to="/workspace/signin"
    //         className="sign-in-btn cursor-pointer fw-500"
    //         Sign in
    //         </Link>
    //         >
    //     </div> */}
    //     <div className="bottom mt-7 d-flex-col">
    //       <div
    //         onClick={() => setActive(0)}
    //         className="back-btn-container cursor-pointer d-flex-row"
    //       >
    //         {/* <ArrowBackIos className="arrow-back" /> */}
    //         {/* <p className="txt fw-500">
    //           {t('back.message')}
    //         </p> */}
    //       </div>
    //       <h4 className="verify-txt fw-600">Verify OTP</h4>
    //       <div className="otp-items-container d-flex-col">
    //         <p className="enter-otp-txt">
    //           Enter the OTP sent to you on {userEmail}
    //         </p>
    //         <div className="otp-input-container" onKeyDown={handleKeyDown}>
              
    //           <OTPInput 
    //           value={otp} 
    //           onChange={onChangeOtpHandler} 
    //           autoFocus 
    //           OTPLength={6} 
    //           otpType="number" 
    //           disabled={false} 
    //           secure />

    //           <p
    //             onClick={onClickResendOTP}
    //             className="resend-otp-btn cursor-pointer"
    //           >
    //             Resend OTP
    //           </p>
    //         </div>

    //         {requiredOtpLimitLengthMatched ? (
    //           <button
    //             onClick={onClickConfirm}
    //             onKeyDown={handleKeyDown}
    //             className="signup-btn-active signup-btn fw-500"
    //           >
    //             {confirmTxt}
    //           </button>
    //         ) : (
    //           <button onClick={onClickConfirm} onKeyDown={handleKeyDown} className="signup-btn fw-500">
    //             {confirmTxt}
    //           </button>
    //         )}

    //         {/* <button onClick={onClickConfirm} className="signup-btn fw-500">
    //           {confirmTxt}
    //         </button> */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="otp-verify-screen-container flex flex-row">
      
      {/* LEFT SECTION */}

      <div className="left-section-container flex flex-col">
        <div className="top flex flex-row items-center">
          <img src={eazybeLogoImg} className="eazybe-logo-img" />
          <p className="txt">
            EazyBe<span className="red-dot">.</span>
          </p>
        </div>
        <h4 className="text-lg font-semibold">Get productive with your team now </h4>
        <img src={diverseTeamImg} className="team-img" />
      </div>

      <div className="right-section-container flex flex-col">
        {/* <div className="sign-in-btn-container flex flex-row">
          <p className="font-medium">Existing user?</p>
          <Link
            to="/workspace/signin"
            className="sign-in-btn cursor-pointer font-medium"
            Sign in
          </Link>
        </div> */}
        <div className="bottom mt-7 flex flex-col">
          <div
            onClick={() => setActive(0)}
            className="back-btn-container cursor-pointer flex flex-row items-center"
          >
            {/* <ArrowBackIos className="arrow-back" /> */}
            {/* <p className="txt font-medium">
              {t('back.message')}
            </p> */}
          </div>
          <h4 className="verify-txt font-semibold text-lg">Verify OTP</h4>
          <div className="otp-items-container flex flex-col">
            <p className="enter-otp-txt">
              Enter the OTP sent to you on {userEmail}
            </p>
            <div className="otp-input-container" onKeyDown={handleKeyDown}>
              
              <OTPInput 
                value={otp} 
                onChange={onChangeOtpHandler} 
                autoFocus 
                OTPLength={6} 
                otpType="number" 
                disabled={false} 
                secure 
                inputClassName="border-2 border-gray-300"
              />

              <p
                onClick={onClickResendOTP}
                className="resend-otp-btn cursor-pointer text-blue-500 hover:text-blue-700"
              >
                Resend OTP
              </p>
            </div>

            {requiredOtpLimitLengthMatched ? (
              <button
                onClick={onClickConfirm}
                onKeyDown={handleKeyDown}
                className="signup-btn-active signup-btn font-medium"
              >
                {confirmTxt}
              </button>
            ) : (
              <button onClick={onClickConfirm} onKeyDown={handleKeyDown} className="signup-btn font-medium">
                {confirmTxt}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>

  );
};

export default OTPVerifyScreen;
