export const APP_URL_PREFIX_V2 = "https://api.eazybe.com/v2/"
// export const APP_URL_PREFIX_V2 = "https://dev.eazybe.com/v2/"
export const APP_URL_PREFIX = "https://eazybe.com/api/v1/whatzapp/";
export const WAHA2 = "https://waweb.eazybe.com" //"http://localhost:3000" // "http://4.246.228.60:3000" //"http://172.174.128.26:3000"  

export const CLOUD_LOADING_TYPES = Object.freeze({
    STARTING: "STARTING",
    PROCESSING: "PROCESSING",
    READY:"READY",
    STOPPED: "STOPPED",
    ERROR:"ERROR",
    CONNECTED: "CONNECTED",
    WORKING:"WORKING",
    SCAN_QR_CODE: "SCAN_QR_CODE",
    FAILED: "FAILED",
    NOT_CONNECTED: "NOT_CONNECTED"
  })

export const getWorkspace_Id = (userInitialData) => {
  return userInitialData?.callyzer_user_mapping?.callyzer_organization?.workspace_id;
} 

export const getUserEmail = (userInitialData) => {
  return userInitialData?.email;
} 

export const getUserMobile = (userInitialData) => {
  return userInitialData?.mobile;
} 

export const getSessionName = (userInitialData) => {
  return userInitialData?.data?.mobile;
}

export const getUserOrgId = (userInitialData) => {
  return userInitialData?.org_id;
}